import { Button, Divider } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import CheckCircleSimple from '../../foundation/assets/svgs/CheckCircleSimple.svg';
import LaptopAlpha from '../../foundation/assets/svgs/LaptopAlpha.svg';
import LaptopBeta from '../../foundation/assets/svgs/LaptopBeta.svg';
import useShowleadSuccessMessage from '../../foundation/cutom_hooks/useLeadSuccessMessage';
import useRole from '../../foundation/cutom_hooks/useRole';
import { selectUser } from '../authentication/redux/selectors';
import { selectClient } from '../client/redux/selectors';
import { setNavIndex } from '../profile/redux/slice';
import Footer from './footer/Footer';

const Lead = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [, , , , , isAgencyLeadView] = useRole();

  const user = useSelector(selectUser);
  const client = useSelector(selectClient);

  useShowleadSuccessMessage(user);

  return (
    <>
      <div className="l-lead-landing">
        <div className="l-lead-landing__portfolio-plan">
          <div className="l-lead-landing__content">
            <div className="l-lead-landing__alpha-left">
              <LaptopAlpha />
            </div>
            <div className="l-lead-landing__alpha-right">
              <h5 className="l-lead-landing__pre-heading">
                Property Growth Plan
              </h5>
              <h2 className="l-lead-landing__heading">
                Your Free Personalised Portfolio Growth Plan
              </h2>
              <Divider />
              <p className="l-lead-landing__paragraph">
                Simply complete our profile builder, and our AI will do all the
                hard work for you by building you a personalised property
                portfolio plan based on your own unique cirumstances.
              </p>
              <h2 className="l-lead-landing__sub-heading">How it works</h2>
              <ul className="l-lead-landing__list">
                <li className="l-lead-landing__list-item">
                  <CheckCircleSimple />
                  Complete the Profile Builder
                </li>
                <li className="l-lead-landing__list-item">
                  <CheckCircleSimple />
                  Our Ai will build your Portfolio Growth Plan
                </li>
                <li className="l-lead-landing__list-item">
                  <CheckCircleSimple />
                  Receive your completed unique plan
                </li>
              </ul>
              <div>
                <Button
                  className="l-lead-landing__button"
                  type="primary"
                  size="large"
                  onClick={() => {
                    dispatch(setNavIndex(2));
                    history.push('/portfolio-growth-plan');
                  }}
                >
                  {client?.planIds?.length ? 'View' : 'Build'} My Portfolio Plan
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="l-lead-landing__suburb-scoring">
          <div className="l-lead-landing__content l-lead-landing__content--reverse">
            <div className="l-lead-landing__beta-left">
              <h5 className="l-lead-landing__pre-heading">
                Real Estate Market Intelligence
              </h5>
              <h2 className="l-lead-landing__heading">
                Discover top performing suburbs with comprehensive insights
              </h2>
              <Divider />
              <p className="l-lead-landing__paragraph">
                Leverage sophisticated machine learning algorithms to identify
                the best suburbs for growth and cashflow across the nation
                allowing you to invest in properties that outperform the average
              </p>
              <div>
                <Button
                  className="l-lead-landing__button"
                  type="primary"
                  size="large"
                  onClick={() => {
                    dispatch(setNavIndex(1));
                    history.push('/remi');
                  }}
                >
                  Explore REMi
                </Button>
              </div>
            </div>
            <div className="l-lead-landing__beta-right">
              <LaptopBeta />
            </div>
          </div>
        </div>
      </div>
      {isAgencyLeadView && <Footer />}
    </>
  );
};

export default Lead;
