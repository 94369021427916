import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Row, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FormInputField from '../../foundation/components/form_input_field/FormInputField';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import SoldLabel from '../../foundation/components/sold_label/SoldLabel';
import SoldOverlay from '../../foundation/components/sold_overlay/SoldOverlay';
import { useRole } from '../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import { numberFormatter } from '../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import {
  fetchPurchaseScenario,
  updatePurchaseScenarioMonth,
} from './redux/async_thunks';
import {
  selectPropPurchaseScenarioIsSold,
  selectPurchaseScenarioFundsRequired,
  selectPurchaseScenarioIsPassed,
  selectPurchaseScenarioIsRedStatus,
  selectPurchaseScenarioNumOfMonths,
  selectPurchaseScenarioPurchaseDate,
  selectPurchaseScenarioShortfallAmount,
} from './redux/selectors';
import LeverageCash from './sections/LeverageCash';
import LeverageEquity from './sections/LeverageEquity';
import Summary from './sections/Summary';

const PurchaseScenario = () => {
  const { isDesktopViewport } = useViewport();
  const { id: propertyId }: any = useParams();

  const dispatch = useAppDispatch();

  const [numMonths, setMonths] = useState<number | undefined>(0);

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const [isClientView, , , , , isAgencyLeadView] = useRole();

  const purchaseDate = useSelector(selectPurchaseScenarioPurchaseDate);

  const isPassed = useSelector(selectPurchaseScenarioIsPassed);

  const isRedStatus = useSelector(selectPurchaseScenarioIsRedStatus);

  const numOfMonths = useSelector(selectPurchaseScenarioNumOfMonths);

  const fundRequired = useSelector(selectPurchaseScenarioFundsRequired);

  const shortfallAmount = useSelector(selectPurchaseScenarioShortfallAmount);

  const isPropertySold = useSelector(selectPropPurchaseScenarioIsSold);

  useEffect(() => {
    setMonths(numOfMonths);
  }, [numOfMonths]);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      if (user?.token) {
        await dispatch(
          fetchPurchaseScenario({
            token: user.token,
            propertyId,
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const updatePurchaseScenario = async (months: number | undefined) => {
    try {
      if (isClientView || isAgencyLeadView || isLoading) {
        return;
      }

      setIsLoading(true);

      if (user?.token) {
        const data = {
          userId: user.user_id,
          propertyId,
          numberOfMonths: months,
        };

        await dispatch(
          updatePurchaseScenarioMonth({
            token: user.token,
            values: data,
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [propertyId]);

  const handleMonthsUpdate = (i: any) => {
    setMonths(i);
  };

  const statusIndicator = useMemo(() => {
    if (isPassed) {
      return 'Passed';
    } else if (isRedStatus === true) {
      return 'Failed';
    } else {
      return `Failed by ${numberFormatter(shortfallAmount || 0, '$')}`;
    }
  }, [isPassed, isRedStatus, shortfallAmount]);

  return (
    <div className="purchase-scenario">
      {isLoading && <FullPageLoader />}
      {isPropertySold && <SoldOverlay />}

      <div className="purchase-scenario__header">
        <Row style={{ marginBottom: 20 }} align="bottom" gutter={[20, 0]}>
          <Col xs={24} md={12}>
            <div className="purchase-scenario__header-title">
              {isDesktopViewport && 'Purchase Scenario'}{' '}
              {isPropertySold && <SoldLabel />}
            </div>
          </Col>
          <Col xs={24} md={12}>
            <Tag
              color={isPassed ? 'green' : 'red'}
              className="purchase-scenario__pass-tag"
            >
              {isPassed ? (
                <CheckCircleOutlined style={{ marginRight: 10 }} />
              ) : (
                <InfoCircleOutlined style={{ marginRight: 10 }} />
              )}
              {statusIndicator}
            </Tag>
          </Col>
        </Row>
        <Row gutter={[20, 0]} align={'bottom'}>
          <Col xs={24} md={8} lg={12}>
            <Row gutter={[20, 0]} align={'bottom'}>
              <Col xs={24} md={24} lg={12}>
                <FormInputField
                  label="Funds Required for This Property"
                  value={fundRequired}
                  type="number"
                  addonBefore="$"
                  disabled
                  style={{ fontWeight: 700 }}
                  labelStyle={{ fontWeight: 700 }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={16} lg={12}>
            <Row
              justify="space-between"
              gutter={[20, 0]}
              align={'bottom'}
              style={{ marginBottom: '10px' }}
            >
              <Col
                xs={24}
                md={12}
                lg={12}
                style={!isDesktopViewport ? { marginBottom: 10 } : {}}
              >
                <FormInputField
                  label="Next Purchase Date"
                  value={purchaseDate}
                  disabled
                  style={{ fontWeight: 700 }}
                  fieldStyle={{ marginBottom: 0 }}
                  labelStyle={{ fontWeight: 700 }}
                />
              </Col>
              <Col
                xs={24}
                md={12}
                lg={12}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  className="c-form-field"
                  style={{ marginBottom: 0, width: '100%' }}
                >
                  <div className="c-form-field__label">
                    <strong style={{ fontWeight: 700 }}>No. of Months:</strong>
                  </div>
                  <div className="c-form-field__wrapper">
                    <InputNumber
                      value={numMonths}
                      className="c-input-number"
                      onChange={handleMonthsUpdate}
                      style={{ width: '100%' }}
                      disabled={isClientView || isAgencyLeadView}
                    />
                  </div>
                </div>
                <Button
                  type="primary"
                  className="purchase-scenario__month-btn"
                  disabled={isClientView || isAgencyLeadView}
                  onClick={() => updatePurchaseScenario(numMonths)}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="purchase-scenario__section-wrapper">
        <Summary isLoading={isLoading} propertyId={propertyId} />
        <LeverageCash isLoading={isLoading} propertyId={propertyId} />
        <LeverageEquity isLoading={isLoading} propertyId={propertyId} />
      </div>
    </div>
  );
};

export default PurchaseScenario;
