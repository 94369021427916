import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../../foundation/components/form_modal/form_modal_types';
import { useRole } from '../../../foundation/cutom_hooks/useRole';
import { KeyValue } from '../../../foundation/types';
import { percentageParser } from '../../../foundation/utils/helperFunctions';
import { selectPlanProjectStartDate } from '../../plan/redux/selectors';
import {
  selectCostByStateValues,
  selectedFocusTypeOptions,
  selectFocusPropertyTypeList,
  selectPreviousPropertyUnderContractDate,
  selectPropertyApexInputs,
  selectPropertyClientRefinancingMaxLvr,
  selectPropertyMaxClientPurchaseLvr,
  selectPropertyPurchaseType,
} from '../redux/selectors';
import { PropertyType } from '../redux/types';

const EXISTING_PURCHASE_TYPE = 'Existing';

export const useFields = (
  monthOptions: KeyValue[],
  values: any,
  setFieldValue: any,
  setValues: any,
) => {
  const [isClientView, , , , , isAgencyLeadView] = useRole();

  const [selectedPropertyType, setSelectedPropertyType] = useState<
    PropertyType | undefined
  >(undefined);

  const purchaseType = useSelector(selectPropertyPurchaseType);
  const planProjectStartDate = useSelector(selectPlanProjectStartDate);
  const costByStateValues = useSelector(selectCostByStateValues);
  const focusTypeOptions = useSelector(selectedFocusTypeOptions);
  const focusPropertyTypeList = useSelector(selectFocusPropertyTypeList);

  const propertyApexInputs = useSelector(selectPropertyApexInputs);

  const clientRefinancingMaxLvr = useSelector(
    selectPropertyClientRefinancingMaxLvr,
  );

  const maxClientPurchaseLvr = useSelector(selectPropertyMaxClientPurchaseLvr);

  const previousPropertyUnderContractDate = useSelector(
    selectPreviousPropertyUnderContractDate,
  );

  const isExisting = useMemo(
    () => purchaseType === EXISTING_PURCHASE_TYPE,
    [purchaseType],
  );

  useEffect(() => {
    if (focusPropertyTypeList && propertyApexInputs) {
      for (const focusPropertyTypeListItem of focusPropertyTypeList) {
        if (focusPropertyTypeListItem.focus === propertyApexInputs.focus) {
          const propertyTypeYearRateList =
            focusPropertyTypeListItem.propertyTypeYearRateList;

          for (const item of propertyTypeYearRateList) {
            if (
              item.year.toString() ===
              dayjs(previousPropertyUnderContractDate).format('YYYY')
            ) {
              const propertyList = item.propertyTypeValueList;
              for (const i of propertyList) {
                if (i.propertyType === propertyApexInputs.propertyType) {
                  setSelectedPropertyType(i);
                  break;
                }
              }
              break;
            }
          }
        }
      }
    }
  }, [focusPropertyTypeList, propertyApexInputs]);

  const underContractYearDisabledYear = useMemo(() => {
    if (previousPropertyUnderContractDate) {
      return previousPropertyUnderContractDate;
    } else {
      return planProjectStartDate;
    }
  }, [previousPropertyUnderContractDate, planProjectStartDate]);

  /**
   * State options
   */
  const statesList = useMemo(() => {
    const states: KeyValue[] = [];
    if (costByStateValues) {
      for (const item of costByStateValues) {
        states.push({
          key: item.key,
          value: item.value,
        });
      }
    }
    return states;
  }, [costByStateValues]);

  /**
   * Property type options list based on focus value.
   */
  const propertyTypeOptions = useMemo(() => {
    const propertyTypes: KeyValue[] = [];
    if (focusPropertyTypeList && values.focus) {
      for (const focusListItem of focusPropertyTypeList) {
        if (focusListItem.focus === values.focus) {
          return focusListItem.newPropertyTypeValues;
        }
      }
    }
    return propertyTypes;
  }, [focusPropertyTypeList, values.focus]);

  const lmiPaymentModeOptions = useMemo(
    () => [
      {
        value: 'Pay Upfront',
        key: 'Pay Upfront',
      },
      {
        value: 'Add To Loan Amount',
        key: 'Add To Loan Amount',
      },
    ],
    [],
  );

  /**
   * Handles purchase lvr value change, and calculate the dependent fields.
   */
  const handlePurchaseLVRChange = (v) => {
    const purchasePrice = values.purchasePrice || 0;
    const purchaseLVR = v || 0;
    const lmiAmount = values.lmiAmount || 0;
    const lmiPaymentMode = values.lmiPaymentMode;
    const valuationAtPurchase = values.valuationAtPurchase || 0;

    if (values) {
      const calcLVR = purchaseLVR / 100;

      let loanAmountAtPurchase: any = 0;

      /**
       * On purchaseLvr we change we need to assign the minimum of purchaseLvr and
       * clientRefinancingMaxLvr to the maxLvr field.
       */
      const formattedClientRefinancingMaxLvr =
        clientRefinancingMaxLvr && clientRefinancingMaxLvr * 100;

      if (
        formattedClientRefinancingMaxLvr &&
        formattedClientRefinancingMaxLvr < v
      ) {
        setFieldValue('maxLvr', formattedClientRefinancingMaxLvr);
      } else {
        setFieldValue('maxLvr', v);
      }

      if (valuationAtPurchase && valuationAtPurchase < purchasePrice) {
        if (lmiPaymentMode === 'Add To Loan Amount') {
          loanAmountAtPurchase = (
            valuationAtPurchase * calcLVR +
            lmiAmount
          ).toFixed(2);
        } else {
          loanAmountAtPurchase = (valuationAtPurchase * calcLVR).toFixed(2);
        }
      } else if (lmiPaymentMode === 'Add To Loan Amount') {
        loanAmountAtPurchase = (purchasePrice * calcLVR + lmiAmount).toFixed(2);
      } else {
        loanAmountAtPurchase = (purchasePrice * calcLVR).toFixed(2);
      }

      setFieldValue('loanAmountAtPurchase', loanAmountAtPurchase);
    }

    setFieldValue('purchaseLvr', v);
  };

  const handleLoanAmountAtPurchaseChange = (v: any) => {
    if (!isExisting) {
      const loanAmountAtPurchase = v || 0;
      const purchasePrice = values?.purchasePrice || 0;
      const lmiAmount = values.lmiAmount || 0;
      const lmiPaymentMode = values.lmiPaymentMode;
      const valuationAtPurchase = values.valuationAtPurchase || 0;
      let purchaseLvr = 0;

      if (values && lmiPaymentMode === 'Add To Loan Amount') {
        if (purchasePrice < valuationAtPurchase) {
          purchaseLvr = (loanAmountAtPurchase - lmiAmount) / purchasePrice;
        } else {
          purchaseLvr =
            (loanAmountAtPurchase - lmiAmount) / valuationAtPurchase;
        }
      } else if (purchasePrice < valuationAtPurchase) {
        purchaseLvr = loanAmountAtPurchase / purchasePrice;
      } else {
        purchaseLvr = loanAmountAtPurchase / valuationAtPurchase;
      }

      setFieldValue('purchaseLvr', percentageParser(purchaseLvr));

      handlePurchaseLVRChange(percentageParser(purchaseLvr));
    }

    setFieldValue('loanAmountAtPurchase', v);
  };

  /**
   * Handles property type onChange event, and updates the relevant fields.
   */
  const handlePropertyTypeChange = (v: string, underContractDate?: string) => {
    if (focusPropertyTypeList) {
      for (const focusPropertyTypeListItem of focusPropertyTypeList) {
        if (focusPropertyTypeListItem.focus === values.focus) {
          const propertyTypeYearRateList =
            focusPropertyTypeListItem.propertyTypeYearRateList;

          const contractDate = underContractDate
            ? underContractDate
            : values.underContractDate;

          for (const item of propertyTypeYearRateList) {
            if (
              item.year.toString() ===
              dayjs(contractDate, 'MM/YYYY').format('YYYY')
            ) {
              const propertyList = item.propertyTypeValueList;
              console.log(propertyList);
              for (const i of propertyList) {
                if (i.propertyType === v) {
                  setSelectedPropertyType(i);
                  const price = isExisting
                    ? i.valuationAtPurchase * i.numberOfUnits
                    : i.purchasePrice * i.numberOfUnits;
                  const rentalYield = price
                    ? (i.rentPerWeek * i.numberOfUnits * 52) / price
                    : 0;

                  const purchaseLvr =
                    maxClientPurchaseLvr && maxClientPurchaseLvr < i.lvr
                      ? percentageParser(maxClientPurchaseLvr)
                      : percentageParser(i.lvr);

                  values = {
                    ...values,
                    propertyType: `${i.propertyType}`,
                    valueAdd: i.strategy,
                    purchasePrice: i.purchasePrice * i.numberOfUnits,
                    valuationAtPurchase:
                      i.valuationAtPurchase * i.numberOfUnits,
                    rentPerWeek: i.rentPerWeek * i.numberOfUnits,
                    purchaseLvr: purchaseLvr,
                    rentalYield: percentageParser(rentalYield),
                    numberOfUnits: i.numberOfUnits,
                    strategy: i.strategy,
                    underContractDate: underContractDate
                      ? underContractDate
                      : values.underContractDate,
                  };

                  setValues({
                    ...values,
                  });

                  handlePurchaseLVRChange(purchaseLvr);
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    }
  };

  /**
   * Handles number of units change
   */
  const handleNumberOfUnitsChange = (v) => {
    const updatedValues = {
      ...values,
      purchasePrice:
        selectedPropertyType && selectedPropertyType.purchasePrice * v,
      valuationAtPurchase:
        selectedPropertyType && selectedPropertyType.valuationAtPurchase * v,
      numberOfUnits: v,
    };

    setValues(updatedValues);
  };

  /**
   * Handles purhcase price value change
   */
  const handlePurchasePriceChange = (v: number) => {
    if (v) {
      const yieldValue = values.rentalYield && values.rentalYield / 100;

      const rentPerWeek =
        yieldValue && parseFloat(((yieldValue * v) / 52).toFixed(2));

      setFieldValue('rentPerWeek', rentPerWeek);
    } else {
      setFieldValue('rentPerWeek', 0);
    }

    let loanAmountAtPurchase = v * (values.purchaseLvr / 100);

    // Calculate Loan amount at purchase
    if (values.lmiPaymentMode === 'Add To Loan Amount') {
      loanAmountAtPurchase += values.lmiAmount;
    }

    setFieldValue('loanAmountAtPurchase', loanAmountAtPurchase.toFixed(2));
    setFieldValue('purchasePrice', v);
  };

  /**
   *
   * Handles valuation at purchase price value change.
   */
  const handleValuationAtPurchasePriceChange = (v: number) => {
    if (isExisting) {
      if (v) {
        const yieldValue = values.rentalYield && values.rentalYield / 100;

        const rentPerWeek =
          yieldValue && parseFloat(((yieldValue * v) / 52).toFixed(2));

        setFieldValue('rentPerWeek', rentPerWeek);
      } else {
        setFieldValue('rentPerWeek', 0);
      }
    }

    setFieldValue('valuationAtPurchase', v);
  };

  /**
   * Handles rent per week change
   */
  const rentPerWeekHandler = (v: number) => {
    const price = isExisting
      ? values.valuationAtPurchase
      : values.purchasePrice;

    const rentalYield = price ? (v * 52) / price : 0;
    setFieldValue('rentalYield', percentageParser(rentalYield));
    setFieldValue('rentPerWeek', v);
  };

  /**
   * Handle rental yield value change
   */
  const rentalYieldHandler = (v: number) => {
    const value = v / 100;

    const price = isExisting
      ? values.valuationAtPurchase
      : values.purchasePrice;

    const rentPerWeek = price ? (value * price) / 52 : 0;
    setFieldValue('rentalYield', v);
    setFieldValue('rentPerWeek', rentPerWeek.toFixed(2));
  };

  const handleFocusTypeChange = (v) => {
    setValues({
      ...values,
      focus: v,
      propertyType: '',
      purchasePrice: 0,
      valuationAtPurchase: 0,
      numberOfUnits: 0,
    });
  };

  const handleUnderContractDateChange = (v) => {
    handlePropertyTypeChange(values.propertyType, dayjs(v).format('MM/YYYY'));
  };

  const handleLmiAmountChange = (v: number) => {
    setFieldValue('lmiAmount', v);

    // Loan Amount At Purchase calculation.
    const calcPurchaseLVR = values.purchaseLvr / 100;

    const amount = values.purchasePrice * calcPurchaseLVR;

    setFieldValue('loanAmountAtPurchase', (amount + v).toFixed(2));

    // LMI Percentage calculation
    const lmiPercentage = v / amount;

    setFieldValue(
      'lmiPercentage',
      parseFloat((lmiPercentage * 100).toFixed(2)),
    );
  };

  const handleLMIPercentageChange = (v: number) => {
    setFieldValue('lmiPercentage', v);
    // Parse percentage
    const value = v / 100;

    const calcPurchaseLVR = values.purchaseLvr / 100;

    const amount = values.purchasePrice * calcPurchaseLVR;

    const lmiAmount = amount * value;

    // Set LMI Amount
    setFieldValue('lmiAmount', parseFloat(lmiAmount.toFixed(2)));

    const loanAmountAtPurchase = amount + lmiAmount;

    // Set Loan amount at purchase
    setFieldValue('loanAmountAtPurchase', loanAmountAtPurchase.toFixed(2));
  };

  const handleLoanTermChange = (v) => {
    // Set loan term
    setFieldValue('loanTerm', v);

    // Calculate and set piterm
    const piTerm = v - values.ioTerm;
    setFieldValue('piTerm', piTerm);
  };

  const handleIoTermChange = (v) => {
    // Set I/O term
    setFieldValue('ioTerm', v);

    // Calculate and set piTerm
    const piTerm = values.loanTerm - v;
    setFieldValue('piTerm', piTerm);
  };

  /**
   * Form fields
   */
  const propertyInfoFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'State',
        name: 'state',
        key: 'state',
        type: 'select',
        isRequired: true,
        requiredMessage: 'State is required',
        colSpan: 11,
        colOffset: 0,
        hasWrapperCol: true,
        disabled: isClientView || isAgencyLeadView,
        options: statesList,
      },
      {
        label: 'Focus',
        name: 'focus',
        key: 'focus',
        type: 'select',
        isRequired: true,
        requiredMessage: 'Focus is required',
        colSpan: 11,
        colOffset: 2,
        hasWrapperCol: true,
        disabled: isClientView || isAgencyLeadView,
        options: focusTypeOptions,
        onChange: handleFocusTypeChange,
      },

      {
        label: 'Days to unconditional',
        name: 'unconditionalDays',
        key: 'unconditionalDays',
        type: 'number-sd',
        placeholder: '20',
        isRequired: true,
        requiredMessage: 'Days to unconditional is required.',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        disabled: isExisting,
      },
      {
        label: 'Days for settlement',
        name: 'settlementDays',
        key: 'settlementDays',
        type: 'number-sd',
        placeholder: '42',
        isRequired: true,
        requiredMessage: 'Days for settlement is required.',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 2,
        disabled: isExisting,
      },
      {
        label: 'Property Type',
        name: 'propertyType',
        key: 'propertyType',
        type: 'select',
        isRequired: true,
        requiredMessage: 'Property Type is required',
        colSpan: 11,
        colOffset: 0,
        hasWrapperCol: true,
        disabled: isClientView || isAgencyLeadView,
        options: propertyTypeOptions,
        onChange: (v) => {
          handlePropertyTypeChange(v);
        },
      },
      {
        label: 'Number of Units',
        name: 'numberOfUnits',
        key: 'numberOfUnits',
        type: 'number-sd',
        min: selectedPropertyType
          ? selectedPropertyType.numberOfUnits
          : undefined,
        isRequired: true,
        requiredMessage: 'Number of Units is required.',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 2,
        disabled:
          isClientView ||
          isAgencyLeadView ||
          (selectedPropertyType && !selectedPropertyType.numberOfUnitsEditable),
        onChange: handleNumberOfUnitsChange,
      },

      {
        label: 'Purchase Price',
        name: 'purchasePrice',
        key: 'purchasePrice',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Purchase Price is required',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        addonBefore: '$',
        disabled: isClientView || isAgencyLeadView,
        onChange: handlePurchasePriceChange,
      },
      {
        label: isExisting ? 'Current Valuation' : 'Valuation At Purchase',
        name: 'valuationAtPurchase',
        key: 'valuationAtPurchase',
        type: 'number',
        isRequired: true,
        requiredMessage: `${
          isExisting ? 'Current Valuation' : 'Valuation At Purchase'
        } is required`,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 2,
        addonBefore: '$',
        disabled: isClientView || isAgencyLeadView,
        onChange: handleValuationAtPurchasePriceChange,
      },
      {
        label: `Rent Per Week`,
        name: 'rentPerWeek',
        key: 'rentPerWeek',
        type: 'number',
        isRequired: true,
        requiredMessage: `Rent Per Week is required`,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        addonBefore: '$',
        disabled: isClientView || isAgencyLeadView,
        onChange: rentPerWeekHandler,
      },
      {
        label: `Rental Yield`,
        name: 'rentalYield',
        key: 'rentalYield',
        type: 'number',
        isRequired: true,
        requiredMessage: `Rental Yield is required`,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 2,
        addonBefore: '%',
        disabled: isClientView || isAgencyLeadView,
        onChange: rentalYieldHandler,
      },
      {
        label: 'FHOG (if any)',
        name: 'fhog',
        key: 'fhog',
        type: 'number',
        placeholder: '',
        isRequired: false,
        hasWrapperCol: true,
        requiredMessage: 'FHOG (if any)',
        colSpan: 11,
        colOffset: 0,
      },
    ],
    [
      planProjectStartDate,
      previousPropertyUnderContractDate,
      underContractYearDisabledYear,
      isClientView,
      isAgencyLeadView,
      isExisting,
      statesList,
      focusTypeOptions,
      values,
      setFieldValue,
      propertyTypeOptions,
      lmiPaymentModeOptions,
    ],
  );

  /**
   * Loan Form Fields
   */
  const loanInfoFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Under Contract Date',
        name: 'underContractDate',
        key: 'underContractDate',
        type: 'month',
        isRequired: true,
        requiredMessage: 'Under Contract Date is required',
        hasWrapperCol: true,
        colSpan: 11,
        disabledDate: underContractYearDisabledYear,
        colOffset: 0,
        format: 'MM/YYYY',
        disabled: false,
        onChange: handleUnderContractDateChange,
      },
      {
        label: ``,
        name: '',
        key: '',
        type: 'placeholder',
        isRequired: true,
        requiredMessage: ``,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 2,
      },

      {
        label: `Purchase LVR`,
        name: 'purchaseLvr',
        key: 'purchaseLvr',
        type: 'number',
        isRequired: true,
        requiredMessage: `Purchase LVR is required`,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        disabled: isClientView || isAgencyLeadView,
        addonBefore: '%',
        step: 0.1,
        min: 0,
        onChange: handlePurchaseLVRChange,
      },
      {
        label: `Max LVR`,
        name: 'maxLvr',
        key: 'maxLvr',
        type: 'number',
        isRequired: true,
        requiredMessage: `Max LVR is required`,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 2,
        disabled: isClientView || isAgencyLeadView,
        addonBefore: '%',
        step: 0.1,
      },
      {
        label: `Loan ${isExisting ? 'Balance' : 'Amount At Purchase'} `,
        name: 'loanAmountAtPurchase',
        key: 'loanAmountAtPurchase',
        type: 'number',
        isRequired: true,
        requiredMessage: `Loan ${
          isExisting ? 'Balance' : 'Amount At Purchase'
        } is required`,
        hasWrapperCol: true,
        colSpan: 11,
        disabledDate: underContractYearDisabledYear,
        onChange: handleLoanAmountAtPurchaseChange,
        colOffset: 0,
        disabled: isClientView || isAgencyLeadView,
        addonBefore: '$',
      },
      {
        label: 'Loan Term (Years)',
        name: 'loanTerm',
        key: 'loanTerm',
        type: 'number-sd',
        placeholder: '',
        isRequired: true,
        requiredMessage: 'Loan Term (Years) is required.',
        hasWrapperCol: true,
        disabled: false,
        colSpan: 11,
        colOffset: 2,
        onChange: handleLoanTermChange,
      },
      {
        label: 'I/O term (Years)',
        name: 'ioTerm',
        key: 'ioTerm',
        type: 'number-sd',
        placeholder: '',
        isRequired: true,
        requiredMessage: 'I/O term (Years) is required.',
        hasWrapperCol: true,
        disabled: isClientView || isAgencyLeadView,
        colSpan: 11,
        colOffset: 0,
        onChange: handleIoTermChange,
        // disabled={values.product !== 'I/O' || isClientView} Check this logic with Pau. We don't have product now.
      },
      {
        label: 'P&I Term (Years)',
        name: 'piTerm',
        key: 'piTerm',
        type: 'text',
        isRequired: true,
        requiredMessage: 'P&I Term (Years) is required.',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 2,
        disabled: true,
        defaulValue:
          values?.piTerm && values?.ioTerm
            ? values?.piTerm - values?.ioTerm
            : '',
      },
      {
        label: 'LMI Payment Mode',
        name: 'lmiPaymentMode',
        key: 'lmiPaymentMode',
        type: 'select',
        isRequired: true,
        requiredMessage: 'LMI Payment Mode is required',
        colSpan: 11,
        colOffset: 0,
        hasWrapperCol: true,
        disabled: isClientView || isAgencyLeadView || isExisting,
        options: lmiPaymentModeOptions,
      },
      {
        label: ``,
        name: '',
        key: '',
        type: 'placeholder',
        isRequired: true,
        requiredMessage: ``,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 2,
      },
    ],
    [
      planProjectStartDate,
      previousPropertyUnderContractDate,
      underContractYearDisabledYear,
      isClientView,
      isAgencyLeadView,
      isExisting,
      statesList,
      focusTypeOptions,
      values,
      setFieldValue,
      propertyTypeOptions,
      lmiPaymentModeOptions,
      handleUnderContractDateChange,
      handleLoanTermChange,
    ],
  );

  const lmiFieldsArray: FieldType[] = useMemo(() => {
    if (values.lmiPaymentMode === 'Add To Loan Amount') {
      return [
        {
          label: `LMI Amount`,
          name: 'lmiAmount',
          key: 'lmiAmount',
          type: 'number',
          isRequired: true,
          requiredMessage: `LMI Amount is required`,
          hasWrapperCol: true,
          colSpan: 11,
          onChange: handleLmiAmountChange,
          colOffset: 0,
          disabled: isClientView || isAgencyLeadView,
          addonBefore: '$',
        },
        {
          label: `LMI Percentage`,
          name: 'lmiPercentage',
          key: 'lmiPercentage',
          type: 'number',
          isRequired: true,
          requiredMessage: `LMI Percentage is required`,
          hasWrapperCol: true,
          colSpan: 11,
          colOffset: 2,
          disabled: isClientView || isAgencyLeadView,
          addonBefore: '%',
          step: 0.1,
          min: 0,
          onChange: handleLMIPercentageChange,
        },
      ];
    }
    return [];
  }, [values, setFieldValue, isClientView, isAgencyLeadView]);

  const valueAddFieldsArray: FieldType[] = useMemo(
    () =>
      isExisting
        ? []
        : [
            {
              label: 'Value Add',
              name: 'valueAdd',
              key: 'valueAdd',
              type: 'text-area',
              placeholder: '',
              isRequired: true,
              requiredMessage: 'Value Add is required.',
              hasWrapperCol: true,
              colSpan: 22,
              colOffset: 0,
              disabled: false,
              rows: 4,
            },
            {
              label: 'Cost Estimation',
              name: 'costEstimation',
              key: 'costEstimation',
              type: 'number',
              placeholder: '100000',
              isRequired: true,
              requiredMessage: 'Cost Estimation is required.',
              hasWrapperCol: true,
              disabled: false,
              colSpan: 10,
              colOffset: 0,
              addonBefore: '$',
            },
            {
              label: 'Uplift In Value',
              name: 'upliftInValue',
              key: 'upliftInValue',
              type: 'number',
              placeholder: '0',
              isRequired: true,
              requiredMessage: 'Uplift In Value is required.',
              hasWrapperCol: true,
              disabled: false,
              colSpan: 10,
              colOffset: 2,
              addonBefore: '$',
            },
            {
              label: 'Uplift In Weekly Rent',
              name: 'upliftInRent',
              key: 'upliftInRent',
              type: 'number',
              placeholder: '',
              isRequired: true,
              disabled: false,
              requiredMessage: 'Uplift In Rent is required.',
              hasWrapperCol: true,
              colSpan: 10,
              colOffset: 0,
              addonBefore: '$',
            },

            {
              label: 'Value Add Timing (Year)',
              name: 'valueAddTimingYear',
              key: 'valueAddTimingYear',
              type: 'year',
              placeholder: '2022',
              isRequired: true,
              requiredMessage: 'Value Add Timing is required.',
              hasWrapperCol: true,
              colSpan: 10,
              colOffset: 2,
            },
            {
              label: 'Value Add Timing (Month)',
              name: 'valueAddTimingMonth',
              key: 'valueAddTimingMonth',
              type: 'select',
              placeholder: 'January',
              isRequired: true,
              requiredMessage: 'Value Add Timing is required.',
              hasWrapperCol: true,
              options: monthOptions,
              colSpan: 10,
              colOffset: 0,
            },
            {
              label: 'LVR (if funded)',
              name: 'lvrIfFunded',
              key: 'lvrIfFunded',
              type: 'number',
              placeholder: '',
              isRequired: false,
              requiredMessage: 'LVR is required.',
              hasWrapperCol: true,
              colSpan: 10,
              colOffset: 2,
              addonBefore: '%',
              min: 0,
              max: 100,
              step: 0.1,
            },
          ],
    [monthOptions, isExisting],
  );

  return [
    valueAddFieldsArray,
    propertyInfoFieldsArray,
    loanInfoFieldsArray,
    lmiFieldsArray,
  ];
};

export const useSellFields = () => {
  const sellFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Sales Price',
        name: 'salePrice',
        key: 'salePrice',
        type: 'number',
        placeholder: '',
        isRequired: true,
        requiredMessage: 'Sales Price is required.',
        hasWrapperCol: true,
        colSpan: 10,
        colOffset: 2,
        disabled: true,
        addonBefore: '$',
      },
      {
        label: 'Estimated Selling Costs',
        name: 'estSellingCosts',
        key: 'estSellingCosts',
        type: 'number',
        placeholder: '',
        isRequired: true,
        requiredMessage: 'Estimated Selling Costs is required.',
        hasWrapperCol: true,
        colSpan: 10,
        colOffset: 2,
        disabled: true,
        addonBefore: '$',
      },
      {
        label: 'Estimated Capital Gains Tax (CGT)',
        name: 'estCgt',
        key: 'estCgt',
        type: 'number',
        placeholder: '',
        isRequired: true,
        requiredMessage: 'Estimated Capital Gains Tax (CGT) is required.',
        hasWrapperCol: true,
        colSpan: 10,
        colOffset: 2,
        disabled: true,
        addonBefore: '$',
      },
    ],
    [],
  );

  return sellFieldsArray;
};
