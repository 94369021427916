import React from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../authentication/redux/selectors';

const BookACall = () => {
  const user = useSelector(selectUser);
  return (
    <div className="l-lead-book-a-call">
      <iframe
        className="l-lead-book-a-call__iframe"
        title="Book A Call"
        src={user?.ctaUrls?.goodFit || ''}
      />
    </div>
  );
};

export default BookACall;
