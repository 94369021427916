import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import Logo from '../../../foundation/assets/svgs/Logo.svg';
import { RouteType } from '../../../foundation/config/routes';
import useRole from '../../../foundation/cutom_hooks/useRole';
import SiderContent from '../sider/SiderContent';

type PGPMenuProps = {
  onClick: () => void;
  routeConfig: RouteType | undefined;
  isVisible: boolean;
};

const PGPMenu = ({ onClick, routeConfig, isVisible }: PGPMenuProps) => {
  const [, , , , , isAgencyLeadView] = useRole();

  const style = {
    paddingBottom: isAgencyLeadView ? '65px' : '0',
  };

  return (
    <div
      className={`pgp-mobile-menu ${isVisible ? ' pgp-mobile-menu--visible ' : 'pgp-mobile-menu--hide '}`}
      style={style}
    >
      <Scrollbars style={{ height: '100%' }}>
        <div className="pgp-mobile-menu__header">
          <div className="pgp-mobile-menu__header-left">
            <div className="c-app-layout__header-logo">
              <Logo />
            </div>
          </div>
          <div className="pgp-mobile-menu__header-right">
            <CloseOutlined onClick={onClick} />
          </div>
        </div>

        <SiderContent
          routeConfig={routeConfig}
          handleMobileMenuClose={onClick}
        />
      </Scrollbars>
    </div>
  );
};

export default PGPMenu;
