import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import useRole from '../../../foundation/cutom_hooks/useRole';
import { selectUser } from '../../authentication/redux/selectors';

const CtaButton = () => {
  const history = useHistory();
  const [, , , , , isAgencyLeadView] = useRole();

  const user = useSelector(selectUser);

  return (
    <>
      {isAgencyLeadView && user?.ctaPlanDetails?.goodFit && (
        <div className="l-lead-cta">
          <Button
            className="l-lead-cta__button"
            type="primary"
            shape="round"
            onClick={() => {
              history.push('/book-a-call');
            }}
          >
            Take the next step
            <ArrowRightOutlined />
          </Button>
        </div>
      )}
    </>
  );
};

export default CtaButton;
