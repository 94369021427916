import React from 'react';

type Props = {
  color?: string;
  size?: string;
  style?: any;
};

function RemiSort({ size = '20', color = '#4E4B66', style = {} }: Props) {
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6401 2.91667C15.6401 2.57149 15.3603 2.29167 15.0151 2.29167C14.6699 2.29167 14.3901 2.57149 14.3901 2.91667V14.4549L12.0819 12.4028C11.824 12.1735 11.4289 12.1967 11.1996 12.4546C10.9702 12.7126 10.9934 13.1077 11.2514 13.337L14.5847 16.3004C14.8216 16.511 15.1785 16.511 15.4153 16.3004L18.7486 13.337C19.0066 13.1077 19.0298 12.7126 18.8004 12.4546C18.5711 12.1967 18.1761 12.1735 17.9181 12.4028L15.6401 14.428V2.91667ZM0.208344 3.33334C0.208344 2.98816 0.488166 2.70834 0.833343 2.70834H10.8333C11.1785 2.70834 11.4583 2.98816 11.4583 3.33334C11.4583 3.67852 11.1785 3.95834 10.8333 3.95834H0.833343C0.488166 3.95834 0.208344 3.67852 0.208344 3.33334ZM0.833343 8.54167C0.488166 8.54167 0.208344 8.82149 0.208344 9.16667C0.208344 9.51185 0.488166 9.79167 0.833343 9.79167H10.8333C11.1785 9.79167 11.4583 9.51185 11.4583 9.16667C11.4583 8.82149 11.1785 8.54167 10.8333 8.54167H0.833343ZM0.833343 14.375C0.488166 14.375 0.208344 14.6548 0.208344 15C0.208344 15.3452 0.488166 15.625 0.833343 15.625H5.83334C6.17852 15.625 6.45834 15.3452 6.45834 15C6.45834 14.6548 6.17852 14.375 5.83334 14.375H0.833343Z"
        fill={color}
      />
    </svg>
  );
}

export default RemiSort;
