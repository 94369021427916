import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createPlan,
  deletePlan,
  editPlanName,
  fetchPlan,
  updatePlan,
} from './async_thunks';
import { Plan, PlanState } from './types';

const initialState: PlanState = {
  plan: undefined,
  lastVisitedPlanId: undefined,
};

export const clientSlice = createSlice({
  name: 'plan',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setPlan: (state, action: PayloadAction<Plan | undefined>) => {
      state.plan = action.payload;
    },
    setLastVisitedPlanId: (state, action) => {
      state.lastVisitedPlanId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createPlan.fulfilled, (state, action) => {
        state.plan = action.payload;
      })
      .addCase(fetchPlan.fulfilled, (state, action) => {
        state.plan = action.payload;
      })
      .addCase(editPlanName.fulfilled, (state, action) => {
        state.plan = action.payload;
      })
      .addCase(deletePlan.fulfilled, (state) => {
        state.plan = undefined;
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        state.plan = action.payload;
      });
  },
});

export const { setPlan, setLastVisitedPlanId } = clientSlice.actions;

export default clientSlice.reducer;
