import { createSlice } from '@reduxjs/toolkit';

import { fetchDashboardData } from './async_thunks';
import { DashboardState } from './types';

const initialState: DashboardState = {
  values: undefined,
};

export const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetDashboard: (state) => {
      state.values = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      if (action.payload) {
        state.values = action.payload;
      }
    });
  },
});

export const { resetDashboard } = dashboard.actions;

export default dashboard.reducer;
