import { Modal } from 'antd';
import React, { useRef, useState } from 'react';

import PlayButton from '../../../foundation/assets/svgs/PlayButton';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import envConstants from '../../../internals/env/env_constants.json';

type LearnMoreVideoModalProps = {
  closeModal: () => void;
};

const LearnMoreVideoModal = ({ closeModal }: LearnMoreVideoModalProps) => {
  const LEARN_MORE_VIDEO = 'understanding_your_portfolio_growth_plan';
  const VIDEO_FILE_EXT = '.mp4';

  const videoRef: any = useRef(null);
  const { isMobileViewport } = useViewport();

  const [isPlayed, setIsPlayed] = useState(false);

  const handleModalClose = () => {
    closeModal();
  };

  const handleFirstPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();

      if (!isPlayed) {
        setIsPlayed(true);
      }
    }
  };

  const modalOpts = {
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    footer: null,
    maskClosable: false,
    centered: true,
    width: 800,
    className: 'thin-padding-modal',
    title: (
      <>
        Understanding Your {isMobileViewport && <br />}
        Portfolio Growth Plan
      </>
    ),
  };

  return (
    <Modal {...modalOpts}>
      <div className="l-learn-more-video">
        <video
          className="l-learn-more-video__video"
          ref={videoRef}
          playsInline
          controls
          disablePictureInPicture
          controlsList="nodownload noplaybackrate"
        >
          <source
            src={
              envConstants.PGP_EXPLAINER_VIDEOS_BASE_URL +
              LEARN_MORE_VIDEO +
              VIDEO_FILE_EXT
            }
            type="video/mp4"
          />
        </video>
        {!isPlayed && (
          <button
            className="l-learn-more-video__play-button"
            onClick={handleFirstPlay}
          >
            <PlayButton />
          </button>
        )}
      </div>
    </Modal>
  );
};

export default LearnMoreVideoModal;
