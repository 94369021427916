import { DownOutlined, TableOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

import House from '../../../foundation/assets/svgs/House';
import { RouteType } from '../../../foundation/config/routes';

type PGPMenuTriggerProps = {
  onClick: () => void;
  routeConfig: RouteType | undefined;
};
const PGPMenuTrigger = ({ onClick, routeConfig }: PGPMenuTriggerProps) => {
  return (
    <Button className="pgp-menu-trigger" onClick={onClick}>
      <div className="pgp-menu-trigger__info-wrapper">
        {routeConfig?.icon ? (
          <routeConfig.icon />
        ) : routeConfig?.headerTitle === 'Standard Tables' ? (
          <TableOutlined />
        ) : (
          <House />
        )}
        <span className="pgp-menu-trigger__info-name">
          {routeConfig?.headerTitle?.includes('Property ')
            ? `Prop ${routeConfig?.headerTitle.split(' ')[1]} - `
            : null}
          {routeConfig?.name}
        </span>
      </div>
      <div className="pgp-menu-trigger__arrow-down">
        <DownOutlined />
      </div>
    </Button>
  );
};

export default PGPMenuTrigger;
