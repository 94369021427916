import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, notification, Row, Switch } from 'antd';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FieldType } from '../../../foundation/components/form_modal/form_modal_types';
import FormSelectField from '../../../foundation/components/form_select_field/FormSelectField';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import SoldLabel from '../../../foundation/components/sold_label/SoldLabel';
import SoldOverlay from '../../../foundation/components/sold_overlay/SoldOverlay';
import { useRole } from '../../../foundation/cutom_hooks/useRole';
import useRouteConfig from '../../../foundation/cutom_hooks/useRouteConfig';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { KeyValue } from '../../../foundation/types';
import formFieldRenderer from '../../../foundation/utils/formUtils';
import { percentageParser } from '../../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import Notes from '../../notes/Notes';
import { selectPlan } from '../../plan/redux/selectors';
import {
  selectMonthsList,
  selectPropertyApexInputs,
  selectPropertyIsPassed,
  selectPropertyIsSold,
  selectPropertyPurchaseType,
} from '../../property/redux/selectors';
import {
  deleteProperty,
  duplicateProperty,
  fetchPlanProperties,
  fetchPropertyAddresses,
  fetchPropertyInfo,
  setPropertyInfo,
} from '../redux/async_thunks';
import { setPlanProperties, setPlanRecords } from '../redux/slice';
import { useFields } from './field_arrays';
import SellForm from './sell_form/SellForm';
import validationSchema from './validation_schema';

const { confirm } = Modal;

const Info = () => {
  const { isDesktopViewport } = useViewport();
  const EXISTING_PURCHASE_TYPE = 'Existing';

  const { id: propertyId }: any = useParams();

  const history = useHistory();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const routeConfig = useRouteConfig(location.pathname);

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const [isClientView, , , , , isAgencyLeadView] = useRole();

  const monthsList = useSelector(selectMonthsList);

  const propertyApexInputs = useSelector(selectPropertyApexInputs);

  const propertyIsPassed = useSelector(selectPropertyIsPassed);

  const plan = useSelector(selectPlan);

  const purchaseType = useSelector(selectPropertyPurchaseType);

  const isPropertySold = useSelector(selectPropertyIsSold);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      if (user?.token) {
        const $promises = [
          dispatch(
            fetchPropertyInfo({ token: user.token, propertyId: propertyId }),
            // @ts-ignore
          ).unwrap(),
        ];

        await Promise.all($promises);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [propertyId]);

  /**
   * Initial form values.
   */
  const initialValues = useMemo(
    () => ({
      ...propertyApexInputs,
      lmiPercentage: percentageParser(propertyApexInputs?.lmiPercentage),
      rentalYield: percentageParser(propertyApexInputs?.rentalYield),
      purchaseLvr: percentageParser(propertyApexInputs?.purchaseLvr),
      maxLvr: percentageParser(propertyApexInputs?.maxLvr),
      lvrIfFunded: percentageParser(propertyApexInputs?.lvrIfFunded),
      estCashReceivedFromSale: 0,
    }),
    [propertyApexInputs],
  );

  const isExisting = useMemo(
    () => purchaseType === EXISTING_PURCHASE_TYPE,
    [purchaseType],
  );

  const monthOptions = useMemo(() => {
    const months: KeyValue[] = [];
    if (monthsList) {
      for (const item of monthsList) {
        months.push({
          key: item,
          value: item,
        });
      }
    }
    return months;
  }, [monthsList]);

  const renderFormFields = useCallback(
    (
      handleChange: any,
      values: any,
      errors: any,
      handleBlur: any,
      setFieldValue: any,
      fieldsArray: FieldType[],
    ) => {
      return formFieldRenderer(
        handleChange,
        values,
        errors,
        handleBlur,
        fieldsArray,
        setFieldValue,
      );
    },
    [],
  );

  const handleFormSubmit = async (values: any) => {
    if (isLoading || isClientView || isAgencyLeadView) {
      return;
    }
    try {
      if (user) {
        setIsLoading(true);

        const data = {
          ...values,
          userId: user.user_id,
          propertyId,
          purchaseLvr: values.purchaseLvr / 100,
          maxLvr: values.maxLvr / 100,
          lvrIfFunded: values.lvrIfFunded / 100,
          switchBackRate: values.switchBackRate / 100,
          lmiPercentage: values.lmiPercentage / 100,
          rentalYield: values.rentalYield / 100,
        };

        await dispatch(
          setPropertyInfo({ token: user.token, values: data }),
          // @ts-ignore
        ).unwrap();

        if (plan) {
          await dispatch(
            fetchPlanProperties({
              token: user.token,
              planId: plan?.planId,
            }),
            // @ts-ignore
          ).unwrap();
        }

        setIsLoading(false);

        notification.success({
          message: 'Success!',
          description: 'Record updated successfully',
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePropertyDelete = async () => {
    if (isClientView || isLoading || isAgencyLeadView) {
      return;
    }
    try {
      setIsLoading(true);

      if (user) {
        const reqData = {
          propertyId,
          userId: user?.user_id,
        };

        const response = await dispatch(
          deleteProperty({ token: user.token, data: reqData }),
          // @ts-ignore
        ).unwrap();

        dispatch(setPlanProperties(response.propertyIds));

        dispatch(setPlanRecords(response.planRecords));

        if (response.latestPropertyId && response.latestPropertyId !== '') {
          history.push(`/properties/${response.latestPropertyId}/info`);
        } else {
          history.push(`/inputs`);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePropertyDuplicate = async () => {
    if (isClientView || isLoading || isAgencyLeadView) {
      return;
    }
    try {
      setIsLoading(true);

      if (user) {
        const reqData = {
          propertyId,
          userId: user.user_id,
        };

        const response = await dispatch(
          duplicateProperty({ token: user.token, data: reqData }),
          // @ts-ignore
        ).unwrap();

        history.push(`/properties/${response.propertyId}/info`);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const showDeleteConfirm = () => {
    if (isClientView || isAgencyLeadView) {
      return;
    }
    confirm({
      title: 'Are you sure you want to delete this property?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action is irreversible.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handlePropertyDelete();
      },
    });
  };

  const showDuplicateConfirm = () => {
    if (isClientView || isAgencyLeadView) {
      return;
    }
    confirm({
      title: 'Are you sure you want to duplicate this property?',
      icon: <ExclamationCircleOutlined />,
      // content: 'You can always delete the property.',
      okText: 'Yes',
      okType: 'default',
      cancelText: 'No',
      onOk() {
        handlePropertyDuplicate();
      },
    });
  };

  // @ts-ignore
  const fetchAddresses = (name: string): Promise<KeyValue[]> => {
    if (user) {
      return fetchPropertyAddresses(user?.token, name, user.user_id)
        .then((res) => {
          const addresses = res.addresses;
          const xyz: KeyValue[] = addresses
            ? addresses.map((item) => ({
                label: item,
                value: item,
              }))
            : [];
          return xyz;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    }
  };

  /**
   * Formik form initialization
   */
  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
    validationSchema: validationSchema,
  });

  const [
    values,
    handleBlur,
    errors,
    handleChange,
    setFieldValue,
    dirty,
    isValid,
    handleSubmit,
    setValues,
  ] = useMemo(() => {
    return [
      formik.values,
      formik.handleBlur,
      formik.errors,
      formik.handleChange,
      formik.setFieldValue,
      formik.dirty,
      formik.isValid,
      formik.handleSubmit,
      formik.setValues,
    ];
  }, [formik]);

  const [
    valueAddFieldsArray,
    propertyInfoFieldsArray,
    loanInfoFieldsArray,
    lmiFieldsArray,
  ] = useFields(monthOptions, values, setFieldValue, setValues);

  // useEffect(() => {
  //   if (values.focus !== initialValues.focus) {
  //     setValues({
  //       ...values,
  //       propertyType: '',
  //       purchasePrice: 0,
  //       valuationAtPurchase: 0,
  //       numberOfUnits: 0,
  //     });
  //   }
  // }, [values.focus, setValues]);

  return (
    <div className="info">
      {isLoading && <FullPageLoader />}
      {isPropertySold && <SoldOverlay />}
      {typeof isClientView === 'boolean' &&
        !isClientView &&
        typeof isAgencyLeadView === 'boolean' &&
        !isAgencyLeadView && <Notes />}
      <form onSubmit={handleSubmit}>
        <Row align="middle" className="info__row">
          <Col
            md={6}
            xs={24}
            style={{ fontSize: 20, fontWeight: 700, zIndex: 5 }}
          >
            {routeConfig?.headerTitle}
            {isPropertySold && <SoldLabel />}
          </Col>
          <Col
            md={5}
            xs={24}
            offset={isDesktopViewport ? 7 : 0}
            className="info__sub-action"
          >
            <div className="info__field">
              <div className="info__field-label">Activate:</div>
              <Switch
                checked={values.isActive}
                size={'default'}
                style={{ width: 80 }}
                disabled={!propertyIsPassed || isClientView || isAgencyLeadView}
                onChange={(v: boolean) => setFieldValue('isActive', v)}
              />
            </div>
          </Col>

          <Col
            md={5}
            xs={24}
            offset={isDesktopViewport ? 1 : 0}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            className="info__action"
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%', height: 40 }}
              disabled={
                !(isValid && dirty) ||
                isLoading ||
                isClientView ||
                isAgencyLeadView
              }
            >
              Update
            </Button>
          </Col>
        </Row>
        <Row className="info__row info__row--shaded">
          <Col sm={11} xs={24}>
            <Row>
              <Col span={24}>
                {/* This is a dynamic field and it fetches the property names from the API. 
                That's why we cannot render this from the fields array. */}
                <FormSelectField
                  label="Name"
                  onChange={(v: string) => setFieldValue('propertyName', v)}
                  value={values.propertyName}
                  error={errors.propertyName}
                  isDebounce
                  fetchOptions={fetchAddresses}
                  disabled={isClientView || isAgencyLeadView}
                />
              </Col>
              {renderFormFields(
                handleChange,
                values,
                errors,
                handleBlur,
                setFieldValue,
                propertyInfoFieldsArray,
              )}
            </Row>
          </Col>
          <Col sm={11} xs={24} offset={isDesktopViewport ? 2 : 0}>
            <Row>
              {renderFormFields(
                handleChange,
                values,
                errors,
                handleBlur,
                setFieldValue,
                loanInfoFieldsArray,
              )}
              {renderFormFields(
                handleChange,
                values,
                errors,
                handleBlur,
                setFieldValue,
                lmiFieldsArray,
              )}
            </Row>
          </Col>
        </Row>
        <Row className="info__row">
          <Col xl={12} xs={24}>
            {!isExisting && (
              <Row className="info__section-title-row">
                <Col className="info__section-title">Value Add</Col>
              </Row>
            )}
            <Row>
              {renderFormFields(
                handleChange,
                values,
                errors,
                handleBlur,
                setFieldValue,
                valueAddFieldsArray,
              )}
            </Row>
          </Col>
          <Col xl={12} xs={24} style={{ position: 'relative' }}>
            <SellForm />
          </Col>
        </Row>
      </form>

      {!isLoading && (
        <Row>
          {(routeConfig.data.isLastProperty ||
            routeConfig.data.propertyIndex + 1 ===
              routeConfig.data.numberOfProperties) && (
            <Col offset={0} xl={5} xs={24}>
              <Button
                type="primary"
                danger
                style={{
                  width: '100%',
                  height: 40,
                  marginBottom: !isDesktopViewport ? 10 : 0,
                }}
                onClick={showDeleteConfirm}
                disabled={isClientView || isAgencyLeadView}
              >
                Delete Property
              </Button>
            </Col>
          )}
          <Col offset={isDesktopViewport ? 1 : 0} xl={5} xs={24}>
            <Button
              type="primary"
              style={{
                width: '100%',
                height: 40,
                marginBottom: !isDesktopViewport ? 10 : 0,
              }}
              onClick={showDuplicateConfirm}
              disabled={isClientView || isAgencyLeadView}
            >
              Duplicate Property
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Info;
