import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import AuthenticationPageImage from '../elements/AuthenticationPageImage';
import ClientAuthenticationForm from '../forms/ClientAuthenticationForm';
import DeleteAccountRequestModal from '../modals/DeleteAccountRequestModal';
import { login } from '../redux/async_thunks';
import {
  selectAuthStrongPassword,
  selectRoleOptions,
} from '../redux/selectors';

const Login = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [isLoginFormLoading, setLoginFormLoading] = useState(false);
  const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] =
    useState(false);

  const roleIds = useSelector(selectRoleOptions);

  const shouldImplementStrongPassword = useSelector(selectAuthStrongPassword);

  const handleFormSubmit = async (values) => {
    try {
      setLoginFormLoading(true);
      const data = { ...values };
      // @ts-ignore
      const response = await dispatch(login({ values: data })).unwrap();

      setLoginFormLoading(false);
      if (response.usersProfile.roleId === roleIds?.agencyLead) {
        // Redirect to initiate a full page reload in order to embed tracking codes in the index.html file
        // Instead of using a regular router history.push
        window.location.href = '/lead-landing';
      } else if (response.usersProfile.roleId === roleIds?.client) {
        history.push('/plan');
      } else {
        history.push('/');
      }
    } catch (error) {
      setLoginFormLoading(false);
    }
  };

  const deleteAccountRequestModalCloseHandler = () => {
    setIsDeleteAccountModalVisible(false);
  };

  useEffect(() => {
    const key = 'delete_account';

    if (sessionStorage.getItem(key)) {
      setIsDeleteAccountModalVisible(true);
      sessionStorage.removeItem(key);
    }
  }, []);

  return (
    <>
      {isDeleteAccountModalVisible && (
        <DeleteAccountRequestModal
          closeHandler={deleteAccountRequestModalCloseHandler}
        />
      )}
      <div className="client-dash-login">
        <ClientAuthenticationForm
          handleFormSubmit={handleFormSubmit}
          isFormLoading={isLoginFormLoading}
          formType="login"
          isStrongPassword={shouldImplementStrongPassword}
        />
        <AuthenticationPageImage />
      </div>
    </>
  );
};

export default Login;
