import { Layout } from 'antd';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { RouteType } from '../../../foundation/config/routes';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import SiderContent from './SiderContent';

const { Sider: LayoutSider } = Layout;

const Sider = ({ routeConfig }: { routeConfig: RouteType | undefined }) => {
  const { isDesktopViewport } = useViewport();

  const theme = isDesktopViewport ? 'light' : 'dark';

  if (routeConfig?.displaySidebar) {
    return (
      <LayoutSider
        className="c-app-layout__sider"
        theme={theme}
        breakpoint="xl"
        collapsedWidth={0}
        collapsible={false} // We are using Layout Sider only on Desktop now.
        // collapsed={isMenuCollapsed}
        // onCollapse={toggleMenuCollapse}
        width={250}
      >
        <Scrollbars style={{ height: '100%' }}>
          <SiderContent routeConfig={routeConfig} />
        </Scrollbars>
      </LayoutSider>
    );
  } else {
    return null;
  }
};

export default Sider;
