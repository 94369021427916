import React from 'react';

function Dollar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="20"
      fill="none"
      viewBox="0 0 13 20"
    >
      <path
        stroke="#0086C9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M6.75.833v18.334m4.167-15h-6.25a2.917 2.917 0 100 5.833h4.166a2.917 2.917 0 010 5.833H1.75"
      ></path>
    </svg>
  );
}

export default Dollar;
