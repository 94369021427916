import React from 'react';

const RemiDownArrow = ({ color, style }: { color?: string; style?: any }) => {
  return (
    <svg
      style={style || {}}
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L6 6L11 1"
        stroke={color || '#00766C'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RemiDownArrow;
