import 'chartjs-adapter-moment';

import { Card, Row } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LineGraph from '../../../foundation/components/line_graph/LineGraph';
import { selectDashboardMonthlyCashflowProjectionData } from '../redux/selectors';

const MonthlyCashflowSection = () => {
  const performaceGraphData = useSelector(
    selectDashboardMonthlyCashflowProjectionData,
  );

  /**
   * X-axis labels.
   */
  const labels = useMemo(() => {
    return performaceGraphData
      ? performaceGraphData.map((item) => moment(item.timeline))
      : [];
  }, [performaceGraphData]);

  /**
   * Formatted data for net cashflow graph.
   */
  const graphData = useMemo(() => {
    return performaceGraphData
      ? performaceGraphData.map((item) => ({
          y: item.monthlyCashFlow,
          x: moment(item.timeline),
        }))
      : [];
  }, [performaceGraphData]);

  /**
   * Formatted data used by the line graph.
   */
  const data = {
    labels,
    datasets: [
      {
        label: 'Net Monthly Cashflow ',
        data: graphData,

        segment: {
          borderColor: (context) => {
            const value = context.p1.parsed.y; // Get the y-value of the current point
            const threshold = 0;

            if (value < threshold) {
              return '#F79009'; // Color for line segments below the threshold
            } else {
              return '#00B2A3'; // Default color for other segments
            }
          },
        },
        borderColor: '#00B2A3',
        backgroundColor: '#00B2A3',
        color: 'rgba(0,0,0,1)',
        pointRadius: 0,

        fill: {
          target: 'origin',
          below: 'transparent', // Area will be red above the origin
          above: 'transparent',
        },
        pointBackgroundColor: (context) => {
          const value = context.dataset.data[context.dataIndex];
          const threshold = 0; // Change this to your desired threshold

          if (value && value.y < threshold) {
            return '#F79009'; // Color for points below the threshold
          } else {
            return '#00B2A3'; // Default color for other points
          }
        },
      },
    ],
  };

  return (
    <Row className="dashboard__section dashboard__annual-cashflow-section">
      <Card className="dashboard__card dashboard__card--no-hover">
        <div className="dashboard__header">
          <div className="dashboard__header-title dashboard__header-title--medium">
            Monthly Net Cashflow
          </div>
        </div>
        <div className="dashboard__chart-wrapper">
          <section className="dashboard__chart-content">
            <LineGraph
              xAxesLabel="Graph Timeline"
              yAxesLabel="Net Cashflow"
              data={data}
              graphTitle="Cashflow Projection"
              areaAboveFill="rgba(0, 178, 163,0.2)"
              displayLegend={false}
            />
          </section>
        </div>
      </Card>
    </Row>
  );
};

export default MonthlyCashflowSection;
