import React from 'react';
import { useSelector } from 'react-redux';

import { useRole } from '../../../../foundation/cutom_hooks/useRole';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import { updatePurchaseSummary } from '../../redux/async_thunks';
import PaymentCategories from './PaymentCategories';
import PaymentSchedules from './PaymentSchedules';
import PaymentSource from './PaymentSource';

type Props = {
  isLoading: boolean;
  propertyId: string;
  setIsLoading: (v: boolean) => any;
};

const Summary = ({ isLoading, setIsLoading, propertyId }: Props) => {
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const [isClientView, , , , , isAgencyLeadView] = useRole();

  /**
   * Updates the data.
   */
  const updateData = async (values: any) => {
    if (isLoading || isClientView || isAgencyLeadView) {
      return;
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);

        if (user?.token) {
          const data = {
            ...values,
            userId: user.user_id,
            propertyId,
          };
          await dispatch(
            updatePurchaseSummary({ token: user.token, values: data }),
            // @ts-ignore
          ).unwrap();

          resolve('');
        }

        setIsLoading(false);
      } catch (error) {
        reject();
        console.log(error);
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="purchase__section">
      <div className="purchase__section-title">Summary</div>
      <div className="purchase__summary-tables">
        <PaymentCategories
          updateData={updateData}
          isClientView={isClientView}
          isAgencyLeadView={isAgencyLeadView}
        />
        <PaymentSchedules
          updateData={updateData}
          isClientView={isClientView}
          isAgencyLeadView={isAgencyLeadView}
        />
        <PaymentSource
          updateData={updateData}
          isClientView={isClientView}
          isAgencyLeadView={isAgencyLeadView}
        />
      </div>
    </div>
  );
};

export default Summary;
