import { Chart as ChartJS, registerables } from 'chart.js';
import React, { useMemo } from 'react';
import { Bubble } from 'react-chartjs-2';

import { useViewport } from '../../cutom_hooks/useViewport';
import { abbreviateNumber, numberFormatter } from '../../utils/helperFunctions';

type Props = {
  yAxesLabel: string;
  xAxesLabel: string;
  data: any;
  graphTitle: string;
  areaAboveFill?: string;
  xAxesType?: string;
  xAxesUnit?: string;
  titleMomentFormat?: string;
};
/**
 * NOTE: This graph component is not totally dynamic because mostly we need to plot
 * amount against time in the Portfolio Planner. In the future if we want to plot other type of values
 * we can make it dynamic.
 */
const BubbleChart = ({
  yAxesLabel,
  xAxesLabel,
  data,
  graphTitle,
  areaAboveFill,
  xAxesUnit,
  xAxesType,
  titleMomentFormat,
}: Props) => {
  const { isTabletViewport, isMobileViewport } = useViewport();
  const legendMargin = {
    id: 'legendMargin',
    afterInit(chart, args, plugin) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        if (originalFit) {
          originalFit.call(this);
        }
        return (this.height += 30);
      };
    },
  };

  ChartJS.register(...registerables, legendMargin);

  const xAxisRotation = isMobileViewport
    ? {}
    : {
        maxRotation: 90, // Rotate labels by 90 degrees
        minRotation: 90, // Ensure all labels are rotated
        autoSkip: true,
      };

  const yAxisPosition = isMobileViewport
    ? {
        position: 'top',
      }
    : {};

  const yAxisRotation = isMobileViewport
    ? {
        maxRotation: 90, // Rotate labels by 90 degrees
        minRotation: 90, // Ensure all labels are rotated
        autoSkip: true,
      }
    : {};

  const xAxisData = useMemo(
    () => ({
      title: {
        text: xAxesLabel,
        display: isMobileViewport || isTabletViewport ? false : true,
        padding: {
          bottom: 10,
        },
      },
      type: xAxesType,
      time: {
        unit: xAxesUnit,
      },

      ticks: {
        ...xAxisRotation,
        color: '#475467',
        callback: (value: any) => {
          return `${value}   `;
        },
      },
    }),
    [
      xAxesLabel,
      xAxesType,
      xAxesUnit,
      xAxisRotation,
      isMobileViewport,
      isTabletViewport,
    ],
  );

  const yAxisData = useMemo(
    () => ({
      ...yAxisPosition,
      title: {
        text: yAxesLabel,
        display: isMobileViewport || isTabletViewport ? false : true,
        padding: {
          bottom: 10,
        },
      },
      ticks: {
        ...yAxisRotation,
        callback: (value: any) => {
          return isTabletViewport || isMobileViewport
            ? isMobileViewport
              ? `  ${abbreviateNumber(value)}`
              : `${abbreviateNumber(value)}  `
            : `${numberFormatter(value, '$')}  `;
        },

        color: '#475467',
      },
    }),
    [
      yAxesLabel,
      isTabletViewport,
      isMobileViewport,
      yAxisPosition,
      yAxisRotation,
    ],
  );

  /**
   * Graph settings
   */
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    fill: {
      target: 'origin',
      below: 'rgba(255, 77, 79, 0.2)', // Area will be red below the origin
      above: areaAboveFill,
    },

    plugins: {
      legend: {
        position: 'top',
        display: true,
        labels: {
          usePointStyle: true,
          color: '#475467',
          padding: 35,
          boxPadding: 100,
        },
      },
      legendMargin: {},
      title: {
        display: false,
        text: graphTitle,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            return `Required Amount: \n${numberFormatter(
              context[0].raw.amount,
              '$',
            )}\n${context[0].raw.propertyName}`;
          },
          label: (context: any) => {
            const value = isMobileViewport
              ? context.parsed.x
              : context.parsed.y;
            return numberFormatter(value, '$');
          },
        },
      },
    },
    scales: {
      xAxes: isMobileViewport ? { ...yAxisData } : { ...xAxisData },
      y: isMobileViewport ? { ...xAxisData, reverse: true } : { ...yAxisData },
    },
  };

  return <Bubble options={options} data={data} />;
};

export default BubbleChart;
