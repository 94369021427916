/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Form, InputNumber, Table } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useContext, useEffect, useRef, useState } from 'react';

import useTableSize from '../../../../foundation/cutom_hooks/useTableSize';
import { percentageParser } from '../../../../foundation/utils/helperFunctions';
import { InterestProjections } from '../../redux/types';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: any;
  handleSave: (record: any, updateAll: boolean) => void;
  setError: (v: boolean) => void;
}

type InterestRateEditableValues = {
  interestRate?: number;
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  setError,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (record?.isEditable) {
      setEditing(!editing);
      const value = percentageParser(record[dataIndex]);
      form.setFieldsValue({
        [dataIndex]: value ? parseFloat(value) : undefined,
      });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();

      const newValues: InterestRateEditableValues = {};

      if (values.interestRate !== undefined) {
        newValues.interestRate = values.interestRate / 100;
      }

      handleSave(
        {
          ...newValues,
          rowId: record.rowId,
        },
        record.rowId === 1 && true,
      );

      setError(false);
    } catch (errInfo) {
      setError(true);
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
          {
            min: 0,
            message: `Field must be greater than or equal to 0.`,
            type: 'number',
          },
          {
            max: 100,
            message: `Field must be less than or equal to 100.`,
            type: 'number',
          },
        ]}
      >
        <InputNumber
          ref={inputRef}
          addonBefore={'%'}
          precision={2}
          step={0.1}
          onPressEnter={save}
          onBlur={save}
          type="number"
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

type Props = {
  defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[];
  updateSource: (v: InterestProjections[]) => void;
  dataSource: InterestProjections[];
  isLoading: boolean;
  isClientView: boolean;
  isAgencyLeadView: boolean;
  setError: (v: boolean) => void;
  scroll?: any;
};

const EditableTable = ({
  defaultColumns,
  updateSource,
  dataSource,
  isLoading,
  setError,
  isClientView,
  isAgencyLeadView,
  scroll,
}: Props) => {
  const handleSave = (row: any, updateAll?: boolean) => {
    let newData = [...dataSource];

    if (updateAll) {
      newData = newData.map((item) => {
        if (item.isEditable) {
          const newValues: InterestRateEditableValues = {};

          if (row.interestRate !== undefined) {
            newValues.interestRate = row.interestRate;
          }

          return {
            ...item,
            ...newValues,
          };
        } else {
          return item;
        }
      });
    } else {
      const index = newData.findIndex((item) => row.rowId === item.rowId);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
    }

    updateSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable || isClientView || isAgencyLeadView) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        setError,
      }),
    };
  });

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        size={useTableSize()}
        pagination={false}
        loading={isLoading}
        scroll={scroll}
      />
    </div>
  );
};

export default EditableTable;
