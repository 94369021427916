import { Chart as ChartJS, registerables } from 'chart.js';
import moment from 'moment';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { useViewport } from '../../cutom_hooks/useViewport';
import { abbreviateNumber, numberFormatter } from '../../utils/helperFunctions';

type Props = {
  yAxesLabel: string;
  xAxesLabel: string;
  data: any;
  graphTitle: string;
  areaAboveFill?: string;
  xAxesType?: string;
  xAxesUnit?: string;
  titleMomentFormat?: string;
};
/**
 * NOTE: This graph component is not totally dynamic because mostly we need to plot
 * amount against time in the Portfolio Planner. In the future if we want to plot other type of values
 * we can make it dynamic.
 */
const BarGraph = ({
  yAxesLabel,
  xAxesLabel,
  data,
  graphTitle,
  areaAboveFill,
  xAxesUnit,
  xAxesType,
  titleMomentFormat,
}: Props) => {
  ChartJS.register(...registerables);

  const { isMobileViewport, isTabletViewport } = useViewport();

  /**
   * Graph settings
   */
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    fill: {
      target: 'origin',
      below: 'rgba(255, 77, 79, 0.2)', // Area will be red above the origin
      above: areaAboveFill,
    },

    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
        text: graphTitle,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            return moment(new Date(context[0].parsed.x)).format(
              titleMomentFormat,
            );
          },
          label: (context: any) => {
            const value = context.parsed.y;
            return numberFormatter(value, '$');
          },
        },
      },
    },
    scales: {
      xAxes: {
        title: {
          text: xAxesLabel,
          display: isMobileViewport || isTabletViewport ? false : true,
          padding: {
            bottom: 10,
          },
        },
        type: xAxesType,
        time: {
          unit: xAxesUnit,
        },
        ticks: {
          maxRotation: 90, // Rotate labels by 90 degrees
          minRotation: 90, // Ensure all labels are rotated
          color: '#475467',
          autoSkip: true,
        },
      },

      y: {
        title: {
          text: yAxesLabel,
          display: isMobileViewport || isTabletViewport ? false : true,
          padding: {
            bottom: 10,
          },
        },
        ticks: {
          callback: (value: any) => {
            return isMobileViewport || isTabletViewport
              ? abbreviateNumber(value)
              : numberFormatter(value, '$');
          },
          color: '#475467',
        },
      },
    },
  };

  return <Bar options={options} data={data} />;
};

export default BarGraph;
