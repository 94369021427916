import { RootState } from '../../../store';

export const selectSuggestions = (state: RootState) =>
  state.suburb_scoring.suggestions;

export const selectQueryValues = (state: RootState) =>
  state.suburb_scoring.queryValues;

export const selectOptions = (state: RootState) => state.suburb_scoring.options;

export const selectResults = (state: RootState) => state.suburb_scoring.results;

export const selectKeyword = (state: RootState) => state.suburb_scoring.keyword;

export const selectEnabledSearchMetrics = (state: RootState) =>
  state.suburb_scoring.enabledSearchMetrics;

export const selectSearchMetricValues = (state: any) => {
  return {
    ...state.suburb_scoring.searchMetricValues,
    sortTypes: state.suburb_scoring.searchMetricValues?.sortTypes.map(
      (option: any) => {
        const { key, value } = option;
        return {
          key: key,
          value: value ? value.charAt(0).toLowerCase() + value.slice(1) : value,
        };
      },
    ),
  };
};

export const selectIsSearchFeatureLoading = (state: RootState) =>
  state.suburb_scoring.isSearchFeatureLoading;

export const selectLastSearchCategoryIdSetting = (state: RootState) =>
  state.suburb_scoring.lastSearchCategoryIdSetting;
