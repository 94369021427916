import { Button, Divider } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import CheckCircle from '../../../foundation/assets/svgs/CheckCircle';
import Logo from '../../../foundation/assets/svgs/Logo.svg';

const EmailSuccessMessage = () => {
  const history = useHistory();
  const handleGoToLogin = (e) => {
    e.preventDefault();
    history.push('/login');
  };

  return (
    <>
      <div className="l-reset-pass__logo-container">
        <Logo />
      </div>
      <div className="l-reset-pass__success-message-wrapper">
        <div>
          <CheckCircle />
        </div>
        <div className="l-reset-pass__success-message-title">Email Sent!</div>
        <div className="l-reset-pass__success-message">
          Please check your email for a link to reset your password.
        </div>
        <Divider />
        <div className="l-reset-pass__link-ref">
          Already have your password?
          <Button
            type="link"
            className="l-reset-pass__link"
            onClick={handleGoToLogin}
          >
            Log in
          </Button>
        </div>
      </div>
    </>
  );
};

export default EmailSuccessMessage;
