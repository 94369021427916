import React from 'react';

function CheckCircleSimple() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.4166 14.3117V15.5C28.415 18.2854 27.5131 20.9956 25.8453 23.2265C24.1776 25.4574 21.8333 27.0894 19.1623 27.8792C16.4912 28.6689 13.6364 28.5741 11.0236 27.6088C8.41084 26.6435 6.1801 24.8595 4.66408 22.5229C3.14805 20.1862 2.42798 17.4221 2.61125 14.6427C2.79452 11.8634 3.87131 9.21774 5.68103 7.10037C7.49075 4.983 9.93643 3.50736 12.6533 2.89351C15.3702 2.27966 18.2128 2.56051 20.757 3.69415M28.4166 5.16665L15.4999 18.0962L11.6249 14.2212"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckCircleSimple;
