import { notification } from 'antd'; // Assuming you're using Ant Design for notifications
import { useEffect } from 'react';

import { User } from '../../features/authentication/redux/types';

const useLeadSuccessMessage = (user?: User) => {
  useEffect(() => {
    if (user) {
      const showMessageKey = 'lead_signup_success_campaign';
      const successCampaign = sessionStorage.getItem(showMessageKey);

      if (typeof successCampaign === 'string' && !!successCampaign) {
        notification.success({
          message: 'Success!',
          placement: 'top',
          description: 'Registration completed! You are now logged in.',
          style: { width: '400px' },
        });

        sessionStorage.removeItem(showMessageKey);
      }
    }
  }, [user]);
};

export default useLeadSuccessMessage;
