import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Logo from '../../../../foundation/assets/svgs/Logo.svg';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';

const AutoSignupSuccess = () => {
  const location = useLocation();
  const history = useHistory();

  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const [leadSignupEmail, setLeadSignupEmail] = useState<
    string | null | (string | null)[]
  >(null);

  const [propertyStrategyLink, setPropertyStrategyLink] = useState<
    string | null
  >(null);

  const handleLoad = () => {
    setIsIframeLoaded(true);
  };

  useEffect(() => {
    if (
      location?.state?.data?.leadSignupEmail &&
      location?.state?.data?.propertyStrategyLink
    ) {
      setLeadSignupEmail(location.state.data.leadSignupEmail);
      setPropertyStrategyLink(location.state.data.propertyStrategyLink);
    } else {
      history.push('/login');
    }
  }, [location]);

  return (
    <>
      {leadSignupEmail &&
        typeof leadSignupEmail === 'string' &&
        propertyStrategyLink && (
          <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {isIframeLoaded && (
              <div
                style={{
                  paddingLeft: '25px',
                  width: '100%',
                  borderBottom: '5px solid #f5f5f5',
                  display: 'flex',
                  alignItems: 'center',
                  height: '74px',
                }}
              >
                <a
                  href="/login"
                  aria-label="Go to login page"
                  style={{ display: 'flex' }}
                >
                  <Logo aria-hidden="true" />
                </a>
              </div>
            )}
            <iframe
              style={{ height: '100%', width: '100%' }}
              className="l-property-search__preview-iframe"
              src={propertyStrategyLink}
              title="Property Strategy"
              onLoad={handleLoad}
            />
          </div>
        )}
      {(!leadSignupEmail || !isIframeLoaded) && (
        <div style={{ position: 'relative', height: '100%' }}>
          <FullPageLoader classNames="h-fixed" message={'Please wait...'} />
        </div>
      )}
    </>
  );
};

export default AutoSignupSuccess;
