import { Layout } from 'antd';
import React, { ReactNode } from 'react';

import { RouteType } from '../../../foundation/config/routes';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import LearnMore from '../../lead/learn_more/LearnMore';
import PGPMenuTrigger from '../pgp_menu/PGPMenuTrigger';

const { Content } = Layout;

type Props = {
  children: ReactNode;
  routeConfig: RouteType | undefined;
  setPGPMobileMenuVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const ContentWrapper = ({
  children,
  routeConfig,
  setPGPMobileMenuVisibility,
}: Props) => {
  const { isDesktopViewport } = useViewport();
  React.useEffect(() => {
    const contentElement = document.querySelector('.c-app-layout__content');
    if (contentElement) {
      contentElement.scrollTo({ top: 0 });
    }
  }, [routeConfig]);

  return (
    <Content className="c-app-layout__content">
      {!isDesktopViewport && (
        <>
          <LearnMore />
          <PGPMenuTrigger
            onClick={() => setPGPMobileMenuVisibility(true)}
            routeConfig={routeConfig}
          />
        </>
      )}
      {routeConfig?.displayHeader && (
        <div className="l-content-header">
          <div className="l-content-header__title">
            {routeConfig?.headerTitle}
          </div>
          <div className="l-content-header__subtitle">
            {routeConfig?.headerSubTitle}
          </div>
        </div>
      )}
      <div>{children}</div>
    </Content>
  );
};

export default ContentWrapper;
