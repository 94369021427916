import { Button } from 'antd';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import {
  selectEnabledSearchMetrics,
  selectIsSearchFeatureLoading,
  selectOptions, // The form values
  selectSearchMetricValues, // These are the dropdown values
} from '../redux/selectors';
import {
  searchMetricsDefaults,
  setEnabledSearchMetrics,
  setOptions,
} from '../redux/slice';
import FilterFieldDropDown from './filter_field/FilterFieldDropDown';
import WiderSearch from './wider_search/WiderSearch';

type FilterProps = {
  fetchResults: any;
  metricKeyValues: any;
  widerSearchValue: boolean;
  onWiderSearchChange: () => void;
};

const Filter = ({
  fetchResults,
  metricKeyValues,
  widerSearchValue,
  onWiderSearchChange,
}: FilterProps) => {
  const dispatch = useDispatch();
  const contentRef = useRef<any>();

  const isSearchFeatureLoading = useSelector(selectIsSearchFeatureLoading);
  const searchMetricValues = useSelector(selectSearchMetricValues);
  const enabledSearchMetrics: any = useSelector(selectEnabledSearchMetrics);
  const options = useSelector(selectOptions);

  const metricChangeHandler = (value, metricKey) => {
    const newValue: any = {};
    const searchMetrics = options.searchMetrics;

    if (Array.isArray(value)) {
      newValue[metricKey] = {
        min: value[0],
        max: value[1],
      };
    } else {
      newValue[metricKey] = value;
    }

    dispatch(
      setOptions({
        ...options,
        searchMetrics: {
          ...searchMetrics,
          ...newValue,
        },
      }),
    );

    const checkValueExists = () => {
      if (Array.isArray(value)) {
        return !Object.values(newValue[metricKey]).every(
          (prop) => prop === null,
        );
      }

      return newValue[metricKey] !== null;
    };

    if (checkValueExists()) {
      if (!enabledSearchMetrics.includes(metricKey)) {
        dispatch(setEnabledSearchMetrics([...enabledSearchMetrics, metricKey]));
      }
    } else {
      dispatch(
        setEnabledSearchMetrics(
          enabledSearchMetrics.filter((metric) => {
            return metric !== metricKey;
          }),
        ),
      );
    }
  };

  const clearFiltersHandler = () => {
    contentRef.current.scrollTop = 0;

    dispatch(
      setOptions({
        ...options,
        searchMetrics: searchMetricsDefaults,
        categoryId: 0,
      }),
    );

    dispatch(setEnabledSearchMetrics([]));
  };

  return (
    <>
      {isSearchFeatureLoading && (
        <FullPageLoader classNames="l-filter-modal__page-loader" />
      )}
      <div className="l-filter-modal">
        <div ref={contentRef} className="l-filter-modal__content">
          <div className="l-filter-modal__content-margin">
            <WiderSearch
              value={widerSearchValue}
              onChange={onWiderSearchChange}
            />
            {searchMetricValues &&
              metricKeyValues.map((metric: any, index: number) => {
                return (
                  <FilterFieldDropDown
                    metricChangeHandler={metricChangeHandler}
                    metricKey={metric.value}
                    key={metric.value}
                    metricKeyValues={metricKeyValues}
                  />
                );
              })}
          </div>
        </div>
        <div className="l-filter-modal__footer">
          <div className="l-filter-modal__button-group">
            <Button
              type="primary"
              onClick={clearFiltersHandler}
              style={{ width: '200px', marginRight: '10px' }}
              disabled={!enabledSearchMetrics.length}
            >
              Clear Filters
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 'auto', width: '200px' }}
              onClick={(e) => {
                e.stopPropagation();
                fetchResults();
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
