import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectClients = (state: RootState) => state.client.list;

export const selectPlan = (state: RootState) => state.plan.plan;

export const selectPlanInputs = (state: RootState) =>
  state.plan.plan?.planInputs;

export const selectPlanProjectStartDate = (state: RootState) =>
  state.plan.plan?.planInputs?.projectStartDate;

export const selectLastVisitedPlan = (state: RootState) => {
  const plans = state.client?.client?.planIds ?? [];
  const clientId = state.client?.client?.clientId ?? '';

  const lastVisitedPlanId: string | null | undefined =
    state.plan.lastVisitedPlanId;

  if (plans?.length && lastVisitedPlanId) {
    const lastPlan = plans.find((plan) => plan.planId === lastVisitedPlanId);
    return { clientId, ...lastPlan };
  }

  return null;
};

export const selectLastVisitedPlanId = (state: RootState) =>
  state.plan.lastVisitedPlanId;
