import React from 'react';

import Logo from '../../../foundation/assets/svgs/DashdotLogo';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';

const Footer = ({ margin }: { margin?: boolean }) => {
  const { isMobileViewport } = useViewport();

  const currentYear = new Date().getFullYear();

  return (
    <div
      className="l-lead-footer"
      style={isMobileViewport && margin ? { marginBottom: '56px' } : {}}
    >
      <Logo />
      <div>
        &copy; Property Pathfinder <span id="year">{currentYear}</span>
      </div>
    </div>
  );
};

export default Footer;
