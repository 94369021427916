import { Button, Col, Divider, notification, Row } from 'antd';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import AutomationLoader from '../../foundation/components/automation_progress/AutomationProgress';
import { FieldType } from '../../foundation/components/form_modal/form_modal_types';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { useRole } from '../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import formFieldRenderer from '../../foundation/utils/formUtils';
import { percentageParser } from '../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import {
  fetchPlan,
  generatePlan,
  updatePlan,
} from '../plan/redux/async_thunks';
import { selectPlan } from '../plan/redux/selectors';
import { fetchPlanProperties } from '../property/redux/async_thunks';
import { useInputFields } from './utils/fields_array';
import UserInputsValidationSchema from './utils/validation_schema';

const UserInput = () => {
  const { isDesktopViewport } = useViewport();
  const [isLoading, setIsLoading] = useState(true);

  const [isAutomationModaVisible, setAutomationModalVisibility] =
    useState(false);

  const dispatch = useAppDispatch();

  const [isClientView, , , , , isAgencyLeadView] = useRole();

  const user = useSelector(selectUser);
  const plan = useSelector(selectPlan);

  const fetchPlanDetails = async () => {
    try {
      if (user && plan) {
        const data = {
          token: user?.token,
          planId: plan?.planId,
        };

        await dispatch(fetchPlan(data));

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * Calls fetch plan details function to fetch plan
   */
  useEffect(() => {
    if (user && plan?.planId) {
      fetchPlanDetails();
    }
  }, [plan?.planId, user]);

  /**
   * Initial form values.
   */
  const initialValues: any = useMemo(() => {
    if (plan?.planInputs) {
      return {
        ...plan?.planInputs,
        maxClientPurchaseLvr: percentageParser(
          plan?.planInputs?.maxClientPurchaseLvr,
        ),
        clientRefinancingMaxLvr: percentageParser(
          plan?.planInputs?.clientRefinancingMaxLvr,
        ),
        maxNegativeCashFlowYearlySavings: percentageParser(
          plan?.planInputs?.maxNegativeCashFlowYearlySavings,
        ),
      };
    } else {
      return {};
    }
  }, [plan]);

  const renderFormFields = useCallback(
    (
      handleChange: any,
      values: any,
      errors: any,
      handleBlur: any,
      formFieldsArray: FieldType[],
      setFieldValue: any,
    ) => {
      return formFieldRenderer(
        handleChange,
        values,
        errors,
        handleBlur,
        formFieldsArray,
        setFieldValue,
      );
    },
    [],
  );

  // Closes the automation progress modal.
  const handleAutomationLoaderModalClose = () => {
    setAutomationModalVisibility(false);
  };

  /**
   * returns the modified request payload.
   */
  const getModifiedRequestPayload = (values: any) => {
    if (user) {
      const data = {
        ...values,
        userId: user.user_id,
        planId: plan?.planId,
        maxClientPurchaseLvr: values?.maxClientPurchaseLvr / 100,
        clientRefinancingMaxLvr: values?.clientRefinancingMaxLvr / 100,
        maxNegativeCashFlowYearlySavings:
          values?.maxNegativeCashFlowYearlySavings / 100,
      };

      return data;
    }

    return {};
  };

  /**
   * This functions calls the generate plan API which basically starts the automations
   * process.
   */
  const onGeneratePlan = async (values) => {
    if (isLoading || isClientView) {
      return;
    }

    if (user?.token) {
      try {
        setIsLoading(true);

        const data = getModifiedRequestPayload(values);

        await dispatch(
          generatePlan({
            data: data,
            token: user?.token,
          }),
        ).unwrap();

        setAutomationModalVisibility(true);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /**
   *
   * Handles Save button's operation
   */
  const handleFormSubmit = async (values: any) => {
    if (isLoading || isClientView) {
      return;
    }

    if (user?.token) {
      try {
        setIsLoading(true);

        const data = getModifiedRequestPayload(values);

        await dispatch(
          updatePlan({
            data: data,
            token: user?.token,
          }),
        ).unwrap();

        if (
          values.availableCash !== initialValues.availableCash &&
          plan?.planId
        ) {
          await dispatch(
            fetchPlanProperties({ token: user.token, planId: plan?.planId }),
          ).unwrap();
        }

        setIsLoading(false);

        notification.success({
          message: 'Success!',
          description: 'Plan updated successfully',
        });
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /**
   * Formik form initialization
   */
  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
    validationSchema: UserInputsValidationSchema,
  });

  const [
    values,
    handleBlur,
    errors,
    handleChange,
    setFieldValue,
    dirty,
    isValid,
  ] = useMemo(() => {
    return [
      formik.values,
      formik.handleBlur,
      formik.errors,
      formik.handleChange,
      formik.setFieldValue,
      formik.dirty,
      formik.isValid,
    ];
  }, [formik]);

  const [
    goalsFieldArray,
    financialsFieldArray,
    timingFieldArray,
    riskProfileFieldArray,
  ] = useInputFields(values, setFieldValue);

  /**
   * targetYear field value calculator.
   */
  useEffect(() => {
    if (values) {
      const targetYear = values.targetYears
        ? dayjs(values?.projectStartDate, 'MM/YYYY')
            // @ts-ignore
            .add(values.targetYears, 'y')
            .format('YYYY')
        : undefined;

      setFieldValue('targetYear', targetYear);
    }
  }, [values.targetYears, values.projectStartDate]);

  /**
   * Loan P&I field calculator
   */
  useEffect(() => {
    if (values.loanTerm) {
      const loanPiTerm = values.loanTerm - values.loanIoTerm;
      setFieldValue('loanPiTerm', loanPiTerm);
    }
  }, [values.loanTerm, values.loanIoTerm]);

  /**
   * Variable to enable/disable save btn.
   */
  const isSaveBtnDisabled = useMemo(() => {
    const {
      financialGoal,
      financialTarget,
      targetYears,
      optimiseFor,
      cashAvailable,
      yearlySavings,
      borrowingCapacity,
    } = values;
    if (
      !(isValid && dirty) ||
      isLoading ||
      isClientView ||
      isAgencyLeadView ||
      !financialGoal ||
      !financialTarget ||
      !targetYears ||
      !optimiseFor ||
      !cashAvailable ||
      !yearlySavings ||
      !borrowingCapacity
    ) {
      return true;
    }
    return false;
  }, [values, isValid, dirty, isLoading, isClientView]);

  return (
    <div style={{ position: 'relative' }}>
      {isAutomationModaVisible && (
        <AutomationLoader onClose={handleAutomationLoaderModalClose} />
      )}
      {isLoading && <FullPageLoader />}

      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col className="h-section-label">Goals</Col>
        </Row>
        <Row justify="space-between" style={{ marginBottom: 20 }}>
          {renderFormFields(
            handleChange,
            values,
            errors,
            handleBlur,
            goalsFieldArray,
            setFieldValue,
          )}
        </Row>

        <Divider />

        <Row>
          <Col className="h-section-label">Financials</Col>
        </Row>
        <Row justify="space-between">
          {renderFormFields(
            handleChange,
            values,
            errors,
            handleBlur,
            financialsFieldArray,
            setFieldValue,
          )}
        </Row>

        <Divider />

        <Row>
          <Col className="h-section-label">Timing</Col>
        </Row>

        <Row justify="space-between">
          {renderFormFields(
            handleChange,
            values,
            errors,
            handleBlur,
            timingFieldArray,
            setFieldValue,
          )}
        </Row>

        <Divider />

        <Row>
          <Col className="h-section-label">Risk Profile</Col>
        </Row>

        <Row justify="space-between">
          {renderFormFields(
            handleChange,
            values,
            errors,
            handleBlur,
            riskProfileFieldArray,
            setFieldValue,
          )}
        </Row>

        <Row justify="end">
          <Col xs={24} sm={6}>
            <div className="c-form__btn-wrapper">
              <Button
                htmlType="submit"
                disabled={isSaveBtnDisabled}
                style={{ height: 40, width: isDesktopViewport ? 180 : '100%' }}
                loading={isLoading}
              >
                Save
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={6}>
            <div className="c-form__btn-wrapper">
              <Button
                type="primary"
                disabled={isSaveBtnDisabled}
                style={{ height: 40, width: isDesktopViewport ? 180 : '100%' }}
                loading={isLoading}
                onClick={() => onGeneratePlan(values)}
              >
                Generate Plan
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default UserInput;
