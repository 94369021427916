import { Modal } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import { selectIsSearchFeatureLoading } from '../../redux/selectors';
import Filter from '../Filter';

const FilterModal = ({
  widerSearchValue,
  onWiderSearchChange,
  fetchResults,
  closeHandler,
  metricKeyValues,
}: any) => {
  const isSearchFeatureLoading = useSelector(selectIsSearchFeatureLoading);
  const { isDesktopViewport } = useViewport();

  return (
    <Modal
      title={'REMi Filters'}
      open={true}
      footer={false}
      onCancel={closeHandler}
      centered
      maskClosable={false}
      width={450}
      styles={{
        body: {
          padding: '0',
          marginBottom: !isDesktopViewport ? '100px' : 0,
        },
      }}
      closable={!isSearchFeatureLoading}
    >
      <Filter
        widerSearchValue={widerSearchValue}
        metricKeyValues={metricKeyValues}
        fetchResults={fetchResults}
        onWiderSearchChange={onWiderSearchChange}
      />
    </Modal>
  );
};

export default FilterModal;
