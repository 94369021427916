import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../../../foundation/components/form_modal/form_modal_types';
import useTableSize from '../../../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../../../foundation/utils/helperFunctions';
import RecordModal from '../../record_modal/RecordModal';
import { selectPurchasePaymentCategories } from '../../redux/selectors';

type Props = {
  updateData: (v: any) => any;
  isClientView: boolean;
  isAgencyLeadView: boolean;
};
const PaymentCategories = ({
  updateData,
  isClientView,
  isAgencyLeadView,
}: Props) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState(undefined);

  const paymentCategories = useSelector(selectPurchasePaymentCategories);
  const onEditRecord = (record: any) => () => {
    if (isClientView || isAgencyLeadView) {
      return;
    }
    setModalData({
      ...record,
      percentage: percentageParser(record.percentage),
    });
    setModalVisibility(true);
  };

  const titlesToBold: string[] = [];
  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Payment Categories',
      dataIndex: 'category',
      key: 'category',
      ellipsis: true,
      width: 300,
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          undefined,
          titlesToBold,
          'category',
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'category');
      },
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', titlesToBold, 'category');
      },
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'type',
      key: 'type',
      width: 80,
      fixed: 'right',
      clientViewHidden: true,
      render: (text: any, record: any) => {
        if (record.isEditable) {
          return (
            <EditOutlined
              onClick={onEditRecord(record)}
              className="c-edit-record-btn"
            />
          );
        }
      },
    },
  ].filter((c: any) => {
    if (isClientView || isAgencyLeadView) {
      return !c.clientViewHidden;
    }
    return true;
  });

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Category',
        name: 'category',
        key: 'category',
        type: 'text',
      },
      {
        label: 'Amount',
        name: 'amount',
        key: 'amount',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Amount is required',
        addonBefore: '$',
      },
      {
        label: 'Percentage',
        name: 'percentage',
        key: 'percentage',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Percentage is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
    ],
    [],
  );

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleFormSubmission = (values: any) => {
    const data = { ...values, percentage: values?.percentage / 100 };
    delete data.isEditable;
    delete data.editableFields;
    delete data.category;
    return updateData(data);
  };

  return (
    <div>
      {isModalVisible && (
        <RecordModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Data`}
          onSubmit={handleFormSubmission}
          formFieldsArray={formFieldsArray}
        />
      )}
      <Table
        columns={columns}
        dataSource={paymentCategories}
        size={useTableSize()}
        pagination={false}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default PaymentCategories;
