import {
  ArrowRightOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Popover, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RemiDownArrow from '../../../foundation/assets/svgs/RemiDownArrow';
import RemiUpArrow from '../../../foundation/assets/svgs/RemiUpArrow';
import useRole from '../../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { selectOptions, selectResults } from '../redux/selectors';
import { setOptions } from '../redux/slice';

const COLUMN_MIN_WIDTH = '80px';

const TableView = ({
  metricKeyValues,
  fetchResults,
  renderBadge,
  renderScore,
  renderSuburbName,
  renderTextWithIcon,
  overridePropertyName,
}: any) => {
  const dispatch = useDispatch();

  const results = useSelector(selectResults);
  const options = useSelector(selectOptions);

  const { isDesktopViewport } = useViewport();
  const [, , , , , isAgencyLeadView] = useRole();

  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [isScrollButtonSticky, setIsScrollButtonSticky] = useState(true);
  const [isScrollRightButtonVisible, setIsScrollRightButtonVisible] =
    useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState({});

  const tableRef: any = useRef(null);

  const handleHeaderCell = (record: any) => {
    const dataIndex = overridePropertyName(record.dataIndex);

    return {
      onClick: () => {
        if (dataIndex === options.sortType) {
          dispatch(
            setOptions({
              ...options,
              sortType: dataIndex,
              orderAsc: !options.orderAsc,
            }),
          );
        } else {
          dispatch(
            setOptions({
              ...options,
              sortType: dataIndex,
              orderAsc: true,
            }),
          );
        }

        fetchResults({
          sortType: dataIndex,
          orderAsc: dataIndex === options.sortType ? !options.orderAsc : true,
        });
      },
    };
  };

  const handleScrollRight = () => {
    if (tableRef?.current?.nativeElement) {
      if (!isDesktopViewport) {
        setIsPopoverOpen({});
      }

      const tableBody =
        tableRef.current.nativeElement.getElementsByClassName(
          'ant-table-body',
        )[0];
      const maxScrollLeft = tableBody.scrollWidth - tableBody.clientWidth;

      tableBody.scrollTo({
        left: maxScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handleLayoutScroll = () => {
    const layoutElement = document.querySelector('.ant-layout');

    if (layoutElement && layoutElement.scrollTop > 200) {
      setShowScrollButtons(true);
    } else {
      setShowScrollButtons(false);
    }

    if (tableRef?.current?.nativeElement) {
      const tableBottom =
        tableRef.current.nativeElement.getBoundingClientRect().bottom;

      const viewportHeight = window.innerHeight;

      if (tableBottom <= viewportHeight) {
        setIsScrollButtonSticky(true);
      } else {
        setIsScrollButtonSticky(false);
      }
    }
  };

  const handleTableScroll = (event: any) => {
    const tableBody = event.target;
    const maxScrollLeft = tableBody.scrollWidth - tableBody.clientWidth;

    if (tableBody.scrollLeft <= maxScrollLeft - 1) {
      setIsScrollRightButtonVisible(true);
    } else {
      setIsScrollRightButtonVisible(false);
    }
  };

  const handleResize = () => {
    if (tableRef?.current?.nativeElement) {
      const tableBody =
        tableRef.current.nativeElement.getElementsByClassName(
          'ant-table-body',
        )[0];
      const maxScrollLeft = tableBody.scrollWidth - tableBody.clientWidth;

      if (tableBody.scrollLeft <= maxScrollLeft - 1) {
        setIsScrollRightButtonVisible(true);
      } else {
        setIsScrollRightButtonVisible(false);
      }
    }
  };

  const renderColumnHeader = (
    dataIndexParam: string,
    titleParam?: string,
    opt?: any,
  ) => {
    const dataIndex = overridePropertyName(dataIndexParam);

    const popoverContent = (
      <button
        className="l-descriptor"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        {!isDesktopViewport && (
          <button
            style={{
              position: 'absolute',
              top: 0,
              right: '4px',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!isDesktopViewport) {
                setIsPopoverOpen({});
              }
            }}
          >
            <CloseOutlined />
          </button>
        )}
        <div
          style={{ textAlign: 'left' }}
          dangerouslySetInnerHTML={{
            __html: results?.descriptors ? results.descriptors[dataIndex] : '',
          }}
        />
      </button>
    );

    const popover = (
      <>
        {!opt?.noDescriptor && (
          <Popover
            content={popoverContent}
            title={null}
            placement="top"
            trigger={isDesktopViewport ? 'hover' : 'click'}
            overlayClassName="no-override"
            open={isPopoverOpen[dataIndex]}
          >
            <button
              style={{ display: 'inline-block' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!isDesktopViewport) {
                  setIsPopoverOpen({
                    [dataIndex]: !isPopoverOpen[dataIndex],
                  });
                }
              }}
            >
              <QuestionCircleOutlined
                style={{
                  cursor: isDesktopViewport ? 'default' : 'pointer',
                  color: '#888',
                  fontSize: '12px',
                }}
              />
            </button>
          </Popover>
        )}
      </>
    );

    const metric = metricKeyValues.find((metric) => {
      return dataIndexParam === metric.value;
    });

    const title = titleParam ?? metric?.key;

    let classNames = title?.includes('Score')
      ? 'l-suburb-scoring__column-header--bold'
      : '';

    if (opt?.noSort) {
      classNames += ' l-suburb-scoring__column-header--default';
    }

    const caretStyle = {
      fontSize: '17px',
      color: '#18362e',
    };

    return (
      <span className={`l-suburb-scoring__column-header ${classNames}`}>
        <span style={{ marginRight: '5px' }}>
          <span style={{ marginRight: '5px' }}>
            {renderTextWithIcon(title, popover)}
          </span>
        </span>
        {dataIndex && options.sortType === dataIndex && !opt?.noSort && (
          <span
            style={{
              display: 'inline-block',
              textAlign: 'center',
              width: '100%',
            }}
          >
            {options.orderAsc ? (
              <RemiUpArrow color="#667085" style={caretStyle} />
            ) : (
              <RemiDownArrow color="#667085" style={caretStyle} />
            )}
          </span>
        )}
      </span>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: renderColumnHeader('suburbName', 'Suburb'),
      dataIndex: 'suburbName',
      key: 'suburbName',
      fixed: 'left',
      width: '100px',
      onHeaderCell: handleHeaderCell,
      render: (data, row) => {
        return <>{renderSuburbName(row)}</>;
      },
    },
    {
      title: renderColumnHeader('growthScore'),
      dataIndex: 'growthScore',
      key: 'growthScore',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderScore(data?.value);
      },
    },
    {
      title: renderColumnHeader('medianSalePrice'),
      dataIndex: 'medianSalePrice',
      key: 'medianSalePrice',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data);
      },
    },
    {
      title: renderColumnHeader(
        'medianSalePriceForecast15Mo',
        'Median Sale Price Forecast (15m)',
      ),
      dataIndex: 'medianSalePriceForecast15Mo',
      key: 'medianSalePriceForecast15Mo',
      onHeaderCell: handleHeaderCell,
      children: [
        {
          title: renderColumnHeader('medianSalePriceForecast15MoLow', 'Low', {
            noDescriptor: true,
            noSort: true,
          }),
          dataIndex: 'medianSalePriceForecast15MoLow',
          key: 'medianSalePriceForecast15MoLow',
          width: COLUMN_MIN_WIDTH,
          render: (data, row) => {
            return (
              <div>
                {renderBadge(data)}
                <br />
                {renderBadge(
                  row?.medianSalePriceGrowthForecast15MoLow?.value,
                  row?.medianSalePriceGrowthForecast15MoLow?.key,
                  'l-suburb-scoring__badge--margin',
                )}
              </div>
            );
          },
        },
        {
          title: renderColumnHeader(
            'medianSalePriceForecast15Mo',
            'Best Estimate',
            {
              noDescriptor: true,
              noSort: true,
            },
          ),
          dataIndex: 'medianSalePriceForecast15Mo',
          key: 'medianSalePriceForecast15Mo',
          width: COLUMN_MIN_WIDTH,
          render: (data, row) => {
            return (
              <div>
                {renderBadge(data)}
                <br />
                {renderBadge(
                  row?.medianSalePriceGrowthForecast15Mo?.value,
                  row?.medianSalePriceGrowthForecast15Mo?.key,
                  'l-suburb-scoring__badge--margin',
                )}
              </div>
            );
          },
        },
        {
          title: renderColumnHeader('medianSalePriceForecast15MoHigh', 'High', {
            noDescriptor: true,
            noSort: true,
          }),
          dataIndex: 'medianSalePriceForecast15MoHigh',
          key: 'medianSalePriceForecast15MoHigh',
          width: COLUMN_MIN_WIDTH,
          render: (data, row) => {
            return (
              <div>
                {renderBadge(data)}
                <br />
                {renderBadge(
                  row?.medianSalePriceGrowthForecast15MoHigh?.value,
                  row?.medianSalePriceGrowthForecast15MoHigh?.key,
                  'l-suburb-scoring__badge--margin',
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: renderColumnHeader('cashFlowScore', 'Cash Flow Score'),
      dataIndex: 'cashFlowScore',
      key: 'cashFlowScore',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderScore(data?.value);
      },
    },
    {
      title: renderColumnHeader('estNetRentalYield'),
      dataIndex: 'estNetRentalYield',
      key: 'estNetRentalYield',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
    {
      title: renderColumnHeader('grossRentalYield', 'Gross Rental Yield'),
      dataIndex: 'grossRentalYield',
      key: 'grossRentalYield',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
    {
      title: renderColumnHeader('medianRentalGrowthForecast15Mo'),
      dataIndex: 'medianRentalGrowthForecast15Mo',
      key: 'medianRentalGrowthForecast15Mo',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
    {
      title: renderColumnHeader('affordabilityScore'),
      dataIndex: 'affordabilityScore',
      key: 'affordabilityScore',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderScore(data?.value);
      },
    },
    {
      title: renderColumnHeader('socioEconomicScore'),
      dataIndex: 'socioEconomicScore',
      key: 'socioEconomicScore',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderScore(data?.value);
      },
    },
    {
      title: renderColumnHeader('population'),
      dataIndex: 'population',
      key: 'population',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data);
      },
    },
    /*
    {
      title: renderColumnHeader(
        'populationForecast5Yr',
        'Population Forecast (5Yrs)',
      ),
      dataIndex: 'populationForecast5Yr',
      key: 'populationForecast5Yr',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data);
      },
    },
    */
    {
      title: renderColumnHeader('populationGrowthForecast5Yr'),
      dataIndex: 'populationGrowthForecast5Yr',
      key: 'populationGrowthForecast5Yr',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
    {
      title: renderColumnHeader('lgaPopulationGrowthForecast5Yr'),
      dataIndex: 'lgaPopulationGrowthForecast5Yr',
      key: 'lgaPopulationGrowthForecast5Yr',
      onHeaderCell: handleHeaderCell,
      width: COLUMN_MIN_WIDTH,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
  ];

  useEffect(() => {
    const layoutElement = document.querySelector('.ant-layout');

    if (layoutElement) {
      layoutElement.addEventListener('scroll', handleLayoutScroll);
    }

    return () => {
      if (layoutElement) {
        layoutElement.removeEventListener('scroll', handleLayoutScroll);
      }
    };
  }, []);

  useEffect(() => {
    const tableBody =
      tableRef?.current?.nativeElement?.getElementsByClassName(
        'ant-table-body',
      )[0];

    if (tableBody) {
      tableBody.removeEventListener('scroll', handleTableScroll);
      tableBody.addEventListener('scroll', handleTableScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', handleTableScroll);
      }
    };
  }, [tableRef?.current]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Table
        columns={columns}
        pagination={false}
        scroll={{ x: 1400 }}
        size={'small'}
        dataSource={results.suburbs}
        style={{ width: '100%' }}
        sticky={true}
        ref={tableRef}
      />
      {showScrollButtons && !isDesktopViewport && (
        <div
          className="l-table-scroll-buttons"
          style={
            isScrollButtonSticky
              ? {
                  position: 'absolute',
                  bottom: isAgencyLeadView ? '155px' : '99px',
                  right: '10px',
                }
              : {
                  bottom: isAgencyLeadView ? '56px' : '0',
                }
          }
        >
          {isScrollRightButtonVisible && (
            <Button
              onClick={handleScrollRight}
              size="small"
              className="l-table-scroll-buttons__item"
              type="primary"
            >
              <ArrowRightOutlined />
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default TableView;
