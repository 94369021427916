import React, {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useRef,
} from 'react';

import useRole from '../../../foundation/cutom_hooks/useRole';
import envConstants from '../../../internals/env/env_constants.json';

const EXPLAINER_VIDEOS = {
  portfolioCashFlow: 'portfolio_cash_flow',
  numberOfProperties: 'number_of_properties',
  portfolioValue: 'portfolio_value',
  totalDebt: 'total_debt',
  totalEquity: 'total_equity',
  nextPurchase: 'next_purchase',
  achievedYear: 'achieved_year',
  dashboardTimeline: 'dashboard_timeline',
  propertyPurchaseRoadmap: 'property_purchase_roadmap',
  howDoesThePortfolioPerform: 'how_does_the_portfolio_perform',
  annualCashFlowGraph: 'annual_cash_flow_graph',
  equityGrowthGraph: 'equity_growth_graph',
};

const VIDEO_FILE_EXT = '.mp4';

type VideoExplainerProps = {
  children: ReactNode;
  setExplainerTargetRef: Dispatch<
    SetStateAction<RefObject<HTMLElement> | null>
  >;
  setExplainerVideoUrl: Dispatch<SetStateAction<string | null>>;
  videoUrlKey: string;
  isLoading: boolean;
  explainerVideoUrl?: string;
};

const VideoExplainer = ({
  children,
  setExplainerTargetRef,
  setExplainerVideoUrl,
  videoUrlKey,
  isLoading,
  explainerVideoUrl,
}: VideoExplainerProps) => {
  const targetRef: any = useRef(null);
  const [, , , , , isAgencyLeadView] = useRole();

  if (!isAgencyLeadView) {
    return <>{children}</>;
  }
  const videoUrlValue = EXPLAINER_VIDEOS[videoUrlKey];
  const isActive = explainerVideoUrl?.includes(videoUrlValue);

  const handleClick = () => {
    if (isActive) {
      setExplainerTargetRef(null);
      setExplainerVideoUrl(null);
    } else {
      setExplainerTargetRef(targetRef);
      setExplainerVideoUrl(
        envConstants.PGP_EXPLAINER_VIDEOS_BASE_URL +
          videoUrlValue +
          VIDEO_FILE_EXT,
      );
    }
  };

  return (
    <div className="l-video-explainer-trigger">
      {children}
      <button
        className={`l-video-explainer-trigger__button${isActive ? ' l-video-explainer-trigger__button--active' : ''}`}
        ref={targetRef}
        onClick={handleClick}
        style={isLoading ? { borderColor: '#fff' } : {}}
      >
        {isLoading && <div className="l-video-explainer-trigger__overlay" />}?
      </button>
    </div>
  );
};

export default VideoExplainer;
