import { Modal } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import { selectIsSearchFeatureLoading } from '../../redux/selectors';
import Sort from '../Sort';

const SortModal = ({ fetchResults, closeHandler }: any) => {
  const isSearchFeatureLoading = useSelector(selectIsSearchFeatureLoading);
  const { isDesktopViewport } = useViewport();

  return (
    <Modal
      title={'REMi Sort Options'}
      open={true}
      footer={false}
      onCancel={closeHandler}
      centered
      maskClosable={false}
      width={450}
      styles={{
        body: {
          padding: '0',
          marginBottom: !isDesktopViewport ? '100px' : 0,
        },
      }}
      closable={!isSearchFeatureLoading}
    >
      <Sort closeHandler={closeHandler} fetchResults={fetchResults} />
    </Modal>
  );
};

export default SortModal;
