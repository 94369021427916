import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, CollapseProps, Popover } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import RemiDownArrow from '../../../foundation/assets/svgs/RemiDownArrow';
import RemiOpenReport from '../../../foundation/assets/svgs/RemiOpenReport';
import RemiUpArrow from '../../../foundation/assets/svgs/RemiUpArrow';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { selectResults } from '../redux/selectors';

const AccordionView = ({
  metricKeyValues,
  fetchResults,
  renderBadge,
  renderScore,
  renderSuburbName,
  renderTextWithIcon,
  handleOpenSuburbReport,
  overridePropertyName,
}: any) => {
  const results = useSelector(selectResults);
  const { isDesktopViewport } = useViewport();
  const [isPopoverOpen, setIsPopoverOpen] = useState({});

  const renderMetricName = (
    dataIndexParam: string,
    suburbCode: string,
    titleParam?: string,
  ) => {
    const dataIndex = overridePropertyName(dataIndexParam);

    const popoverContent = (
      <button
        className="l-descriptor"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        {!isDesktopViewport && (
          <button
            style={{
              position: 'absolute',
              top: 0,
              right: '4px',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!isDesktopViewport) {
                setIsPopoverOpen({});
              }
            }}
          >
            <CloseOutlined style={{ color: '#fff' }} />
          </button>
        )}
        <div
          style={{ textAlign: 'left' }}
          dangerouslySetInnerHTML={{
            __html: results?.descriptors ? results.descriptors[dataIndex] : '',
          }}
        />
      </button>
    );

    const dataIndexSuburbCode = `${dataIndex}-${suburbCode}`;

    const popover = (
      <Popover
        content={popoverContent}
        title={null}
        placement="top"
        trigger={isDesktopViewport ? 'hover' : 'click'}
        overlayClassName="no-override"
        open={isPopoverOpen[dataIndexSuburbCode]}
      >
        <button
          style={{ display: 'inline-block' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!isDesktopViewport) {
              setIsPopoverOpen({
                [dataIndexSuburbCode]: !isPopoverOpen[dataIndexSuburbCode],
              });
            }
          }}
        >
          <QuestionCircleOutlined
            style={{
              color: '#888',
              fontSize: '.8em',
              cursor: isDesktopViewport ? 'default' : 'pointer',
            }}
          />
        </button>
      </Popover>
    );

    const metric = metricKeyValues.find((metric) => {
      return dataIndexParam === metric.value;
    });

    const title = titleParam ?? metric?.key;

    const classNames = title?.includes('Score')
      ? 'l-suburb-scoring__column-header--bold'
      : '';

    return (
      <span className={`l-suburb-scoring__column-header ${classNames}`}>
        <span style={{ marginRight: '5px' }}>
          <span style={{ marginRight: '5px' }}>
            {renderTextWithIcon(title, popover)}
          </span>
        </span>
      </span>
    );
  };

  return (
    <div className="l-suburb-scoring__accordion">
      {(results?.suburbs || []).map((result: any) => {
        if (!result) {
          return <></>;
        }

        const {
          suburbCode,
          growthScore,
          cashFlowScore,
          affordabilityScore,
          socioEconomicScore,
          medianSalePrice,
          medianSalePriceForecast15Mo,
          medianSalePriceForecast15MoHigh,
          medianSalePriceForecast15MoLow,
          medianSalePriceGrowthForecast15Mo,
          medianSalePriceGrowthForecast15MoHigh,
          medianSalePriceGrowthForecast15MoLow,
          estNetRentalYield,
          grossRentalYield,
          medianRentalGrowthForecast15Mo,
          population,
          populationGrowthForecast5Yr,
          lgaPopulationGrowthForecast5Yr,
        } = result;

        const items: CollapseProps['items'] = [
          {
            key: '1',
            label: (
              <div className="l-suburb-scoring__accordion-sub-header">
                {renderMetricName('growthScore', suburbCode)}
                {renderScore(growthScore?.value, growthScore?.key)}
              </div>
            ),
            children: (
              <div className="l-suburb-scoring__accordion-content">
                <ul className="l-suburb-scoring__accordion-list">
                  <li className="l-suburb-scoring__accordion-list-item">
                    <div className="l-suburb-scoring__accordion-key">
                      {renderMetricName('medianSalePrice', suburbCode)}
                    </div>
                    <div className="l-suburb-scoring__accordion-value">
                      {renderBadge(medianSalePrice)}
                    </div>
                  </li>
                  <li className="l-suburb-scoring__accordion-list-item">
                    <div className="l-suburb-scoring__accordion-key">
                      {renderMetricName(
                        'medianSalePriceForecast15Mo',
                        suburbCode,
                        'Median Sale Price Forecast (15m)',
                      )}
                    </div>
                  </li>
                  <li className="l-suburb-scoring__accordion-list-item">
                    <div className="l-suburb-scoring__accordion-column">
                      <span>Low Est</span>
                      <span>{renderBadge(medianSalePriceForecast15MoLow)}</span>
                      <span>
                        {renderBadge(
                          medianSalePriceGrowthForecast15MoLow?.value,
                          medianSalePriceGrowthForecast15MoLow?.key,
                          'l-suburb-scoring__badge--margin',
                        )}
                      </span>
                    </div>
                    <div className="l-suburb-scoring__accordion-column">
                      <span>Best Est</span>
                      <span>{renderBadge(medianSalePriceForecast15Mo)}</span>
                      <span>
                        {renderBadge(
                          medianSalePriceGrowthForecast15Mo?.value,
                          medianSalePriceGrowthForecast15Mo?.key,
                          'l-suburb-scoring__badge--margin',
                        )}
                      </span>
                    </div>
                    <div className="l-suburb-scoring__accordion-column">
                      <span>High Est</span>
                      <span>
                        {renderBadge(medianSalePriceForecast15MoHigh)}
                      </span>
                      <span>
                        {renderBadge(
                          medianSalePriceGrowthForecast15MoHigh?.value,
                          medianSalePriceGrowthForecast15MoHigh?.key,
                          'l-suburb-scoring__badge--margin',
                        )}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            ),
          },
          {
            key: '2',
            label: (
              <div className="l-suburb-scoring__accordion-sub-header">
                {renderMetricName(
                  'cashFlowScore',
                  suburbCode,
                  'Cash Flow Score',
                )}
                {renderScore(cashFlowScore.value, cashFlowScore.key)}
              </div>
            ),
            children: (
              <div className="l-suburb-scoring__accordion-content">
                <ul className="l-suburb-scoring__accordion-list">
                  <li className="l-suburb-scoring__accordion-list-item">
                    <div className="l-suburb-scoring__accordion-key">
                      {renderMetricName('estNetRentalYield', suburbCode)}
                    </div>
                    <div className="l-suburb-scoring__accordion-value">
                      {renderBadge(
                        estNetRentalYield?.value,
                        estNetRentalYield?.key,
                      )}
                    </div>
                  </li>
                  <li className="l-suburb-scoring__accordion-list-item">
                    <div className="l-suburb-scoring__accordion-key">
                      {renderMetricName(
                        'grossRentalYield',
                        suburbCode,
                        'Gross Rental Yield',
                      )}
                    </div>
                    <div className="l-suburb-scoring__accordion-value">
                      {renderBadge(
                        grossRentalYield?.value,
                        grossRentalYield?.key,
                      )}
                    </div>
                  </li>
                  <li className="l-suburb-scoring__accordion-list-item">
                    <div className="l-suburb-scoring__accordion-key">
                      {renderMetricName(
                        'medianRentalGrowthForecast15Mo',
                        suburbCode,
                      )}
                    </div>
                    <div className="l-suburb-scoring__accordion-value">
                      {renderBadge(
                        medianRentalGrowthForecast15Mo?.value,
                        medianRentalGrowthForecast15Mo?.key,
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            ),
          },
          {
            key: '3',
            label: (
              <div className="l-suburb-scoring__accordion-sub-header l-suburb-scoring__accordion-sub-header--empty">
                {renderMetricName('affordabilityScore', suburbCode)}
                {renderScore(affordabilityScore.value, affordabilityScore.key)}
              </div>
            ),
            children: null,
            collapsible: 'disabled',
            showArrow: false,
          },
          {
            key: '4',
            label: (
              <div className="l-suburb-scoring__accordion-sub-header">
                {renderMetricName('socioEconomicScore', suburbCode)}
                {renderScore(socioEconomicScore.value, socioEconomicScore.key)}
              </div>
            ),
            children: (
              <div className="l-suburb-scoring__accordion-content">
                <ul className="l-suburb-scoring__accordion-list">
                  <li className="l-suburb-scoring__accordion-list-item">
                    <div className="l-suburb-scoring__accordion-key">
                      {renderMetricName('population', suburbCode)}
                    </div>
                    <div className="l-suburb-scoring__accordion-value">
                      {renderBadge(population)}
                    </div>
                  </li>
                  <li className="l-suburb-scoring__accordion-list-item">
                    <div className="l-suburb-scoring__accordion-key">
                      {renderMetricName(
                        'populationGrowthForecast5Yr',
                        suburbCode,
                      )}
                    </div>
                    <div className="l-suburb-scoring__accordion-value">
                      {renderBadge(
                        populationGrowthForecast5Yr?.value,
                        populationGrowthForecast5Yr?.key,
                      )}
                    </div>
                  </li>
                  <li className="l-suburb-scoring__accordion-list-item">
                    <div className="l-suburb-scoring__accordion-key">
                      {renderMetricName(
                        'lgaPopulationGrowthForecast5Yr',
                        suburbCode,
                      )}
                    </div>
                    <div className="l-suburb-scoring__accordion-value">
                      {renderBadge(
                        lgaPopulationGrowthForecast5Yr?.value,
                        lgaPopulationGrowthForecast5Yr?.key,
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            ),
          },
        ];

        return (
          <div
            key={result.suburbCode}
            className="l-suburb-scoring__accordion-entry"
          >
            <div className="l-suburb-scoring__accordion-header">
              {renderSuburbName(result)}
              <Button
                className="l-suburb-scoring__accordion-open-report"
                type="link"
                onClick={() => {
                  handleOpenSuburbReport(result.suburbCode);
                }}
              >
                <RemiOpenReport />
              </Button>
            </div>
            <Collapse
              items={items}
              expandIconPosition="end"
              bordered={false}
              expandIcon={({ isActive }) => {
                return isActive ? <RemiUpArrow /> : <RemiDownArrow />;
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AccordionView;
