import {
  ArrowRightOutlined,
  CloseCircleFilled,
  CloseOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  notification,
  Popover,
  Result,
  Spin,
  Switch,
  Tooltip,
} from 'antd';
import { each, isEmpty, map as pluck } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import reactStringReplace from 'react-string-replace';

import {
  selectEnabledSearchMetrics,
  selectIsSearchFeatureLoading,
  selectKeyword,
  selectOptions,
  selectQueryValues,
  selectResults,
  selectSearchMetricValues,
  selectSuggestions,
} from '../../features/suburb_scoring/redux/selectors';
import {
  resetResults,
  resetSuggestions,
  searchMetricsDefaults,
  setEnabledSearchMetrics,
  setIsSearchFeatureLoading,
  setKeyword,
  setLastSearchCategoryIdSetting,
  setOptions,
  setQueryValues,
} from '../../features/suburb_scoring/redux/slice';
import Close from '../../foundation/assets/svgs/Close';
import Filter from '../../foundation/assets/svgs/Filter';
import RemiInfo from '../../foundation/assets/svgs/RemiInfo';
import RemiSort from '../../foundation/assets/svgs/RemiSort';
import RemiUpArrow from '../../foundation/assets/svgs/RemiUpArrow';
import Search from '../../foundation/assets/svgs/Search';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import useShowleadSuccessMessage from '../../foundation/cutom_hooks/useLeadSuccessMessage';
import useRole from '../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import envConstant from '../../internals/env/env_constants.json';
import {
  resetReport,
  resetSuburb,
  resetTemplate,
  setIsEditedVersion,
  setIsReportToggleEnabled,
} from '../ai_report/redux/slice';
import { selectUser } from '../authentication/redux/selectors';
import Footer from '../lead/footer/Footer';
import { setNavIndex } from '../profile/redux/slice';
import AccordionView from './accordion_view/AccordionView';
import FilterModal from './filter/filter_modal/FilterModal';
import Pagination from './pagination/Pagination';
import {
  suburbScoreSearch,
  suburbScoreSuggestions,
} from './redux/async_thunks';
import SortModal from './sort/sort_modal/SortModal';
import TableView from './table_view/TableView';

const SuburbScoring = () => {
  const keywordMinLength = envConstant.SUBURB_SCORE_KEYWORD_MIN_LENGTH;

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(selectUser);
  const suggestions = useSelector(selectSuggestions);
  const queryValues = useSelector(selectQueryValues);
  const keyword = useSelector(selectKeyword);
  const results = useSelector(selectResults);
  const options = useSelector(selectOptions);
  const enabledSearchMetrics = useSelector(selectEnabledSearchMetrics);
  const searchMetricValues = useSelector(selectSearchMetricValues);
  const isSearchFeatureLoading = useSelector(selectIsSearchFeatureLoading);

  const { isDesktopViewport, isMobileViewport } = useViewport();
  const [, , , , , isAgencyLeadView] = useRole();

  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [searchKeywordDisplay, setSearchKeywordDisplay] = useState('');
  const [width, setWidth] = useState<any>(0);
  const [isFilterModalActive, setIsFilterModalActive] = useState(false);
  const [isSortModalVisible, setIsSortModalVisible] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState({});

  const phantomTextRef = useRef();
  const inputRef = useRef(null);
  const selectedRef = useRef();

  const metricKeyValues = [
    {
      key: 'Growth Potential Score',
      value: 'growthScore',
    },
    {
      key: 'Median Sale Price',
      value: 'medianSalePrice',
    },
    {
      key: 'Median Sale Price Growth Forecast (15m)',
      value: 'medianSalePriceGrowthForecast15Mo',
    },
    {
      key: 'Cashflow Score',
      value: 'cashflowScore',
    },
    {
      key: 'Gross Rental Yield',
      value: 'grossRentalYield',
    },
    {
      key: 'Est Net Rental Yield',
      value: 'estNetRentalYield',
    },
    {
      key: 'Median Rental Growth Forecast (15m)',
      value: 'medianRentalGrowthForecast15Mo',
    },
    {
      key: 'Affordability Score',
      value: 'affordabilityScore',
    },
    {
      key: 'Socio-economic Score',
      value: 'socioEconomicScore',
    },
    {
      key: 'Population',
      value: 'population',
    },
    {
      key: 'Suburb Population Growth Forecast (5yrs)',
      value: 'populationGrowthForecast5Yr',
    },
    {
      key: 'LGA Population Growth Forecast (5yrs)',
      value: 'lgaPopulationGrowthForecast5Yr',
    },
  ];

  const scrollToTopmost = () => {
    const elements = document.getElementsByClassName('c-app-layout');

    if (elements.length > 0) {
      const element = elements[0] as HTMLElement;
      element.scrollTop = 0;
    }
  };

  const scrollToBottom = (ref: any, scrollHeight = 0) => {
    if (ref?.current) {
      ref.current.scrollTop = ref?.current?.scrollHeight - scrollHeight;
    }
  };

  const scrollToTop = () => {
    const scrollToElement = document.getElementById('h2-anchor');

    if (scrollToElement) {
      scrollToElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const prepareSearchMetrics = (
    options: any,
    enabledSearchMetrics: any,
    searchMetricValues: any,
  ) => {
    let searchMetricsChecked: any = {};

    const assignValues = (metricValue: any, metricKey: string) => {
      const minMaxLimits = searchMetricValues?.filters[metricKey];
      searchMetricsChecked[metricKey] = {};

      if (metricValue.min !== null && metricValue.min !== minMaxLimits?.min) {
        searchMetricsChecked[metricKey].min = metricValue.min;
      }

      if (metricValue.max !== null && metricValue.max !== minMaxLimits?.max) {
        searchMetricsChecked[metricKey].max = metricValue.max;
      }
    };

    if (!enabledSearchMetrics?.length) {
      return null;
    }

    each(options.searchMetrics, (metricValue: any, metricKey: string) => {
      if (enabledSearchMetrics.includes(metricKey)) {
        assignValues(metricValue, metricKey);
      }
    });

    // Remove empty objects
    searchMetricsChecked = Object.keys(searchMetricsChecked)
      .filter((key) => {
        return (
          !!searchMetricsChecked[key] || !isEmpty(searchMetricsChecked[key])
        );
      })
      .reduce((obj: any, key) => {
        obj[key] = searchMetricsChecked[key];
        return obj;
      }, {});

    return isEmpty(searchMetricsChecked) ? null : searchMetricsChecked;
  };

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchResults = async (optionsParam?: any, isClearFilter?: boolean) => {
    dispatch(setIsSearchFeatureLoading(true));

    try {
      const searchMetricsChecked = prepareSearchMetrics(
        options,
        enabledSearchMetrics,
        searchMetricValues,
      );

      const searchValues = queryValues.length
        ? pluck(queryValues, 'value')
        : null;

      const searchOptions = optionsParam?.pageIndex
        ? {
            ...options,
            ...optionsParam,
          }
        : {
            ...options,
            ...optionsParam,
            pageIndex: 1,
          };

      const data = isClearFilter
        ? {
            userId: user?.user_id,
            categoryId: 0,
            orderAsc: false,
            searchMetrics: null,
            searchValues: null,
            pageIndex: 1,
          }
        : {
            userId: user?.user_id,
            searchValues: searchValues,
            ...searchOptions,
            ...{
              searchMetrics: searchMetricsChecked,
            },
          };

      const response: any = await dispatch(
        suburbScoreSearch({
          token: user?.token,
          data: data,
        }),
      )
        // @ts-ignore
        .unwrap();

      const hasNoResults =
        response &&
        !response?.pageCount &&
        response?.pageIndex &&
        response?.pageIndex === 1;

      if (hasNoResults) {
        notification.warning({
          message: 'No results found',
          description:
            'Sorry, the search and filter combination yielded no results.',
          placement: 'topRight',
        });

        dispatch(resetResults());
      }

      dispatch(setLastSearchCategoryIdSetting(data.categoryId));

      if (!hasNoResults && optionsParam?.pageIndex) {
        scrollToTop();
      }
    } catch (error) {
      errorHandler(error);
      dispatch(setLastSearchCategoryIdSetting(0));
    }

    setIsFilterModalActive(false);
    dispatch(setKeyword(undefined));
    dispatch(resetSuggestions());
    dispatch(setIsSearchFeatureLoading(false));
  };

  const fetchSuggestions = async (searchKeyword: string) => {
    setIsSuggestionsLoading(true);

    try {
      await dispatch(
        suburbScoreSuggestions({
          data: {
            userId: user?.user_id,
            searchKeyword: searchKeyword,
            selectedValues: queryValues.length
              ? pluck(queryValues, 'value')
              : null,
          },
          token: user?.token,
        }),
      );
    } catch (error) {
      errorHandler(error);
    }

    dispatch(setKeyword(searchKeyword));
    setIsSuggestionsLoading(false);
  };

  const setSearchKeywordRealValue = (e: any) => {
    const value = e.target.value;

    if (!value.length) {
      setSearchKeywordDisplay('');
    }

    if (value.length < keywordMinLength) {
      dispatch(setKeyword(undefined));
      dispatch(resetSuggestions());
    } else {
      fetchSuggestions(value);
    }
  };

  const isAllSelected =
    suggestions.filter((suggestion: any) => {
      return queryValues.some((queryValue: any) => {
        return queryValue.value === suggestion.value;
      });
    }).length === suggestions.length;

  const handleFilterModalClose = () => {
    setIsFilterModalActive(false);
  };

  const handleSortModalClose = () => {
    setIsSortModalVisible(false);
  };

  const handleCta = () => {
    dispatch(setNavIndex(2));
    if (user?.ctaPlanDetails?.goodFit === true) {
      history.push('/book-a-call');
    } else if (
      user?.ctaPlanDetails === null ||
      user?.ctaPlanDetails?.goodFit === null
    ) {
      history.push('/portfolio-growth-plan');
    }
  };

  const widerSearchValue = useMemo(() => {
    if (options.categoryId === 0) {
      return false;
    } else {
      return true;
    }
  }, [options.categoryId]);

  const onWiderSearchChange = () => {
    let categoryId = 0;

    if (!widerSearchValue) {
      categoryId = 1;
      dispatch(
        setEnabledSearchMetrics([...enabledSearchMetrics, 'categoryId']),
      );
    } else {
      dispatch(
        setEnabledSearchMetrics(
          enabledSearchMetrics.filter((metric) => {
            return metric !== 'categoryId';
          }),
        ),
      );
    }

    dispatch(
      setOptions({
        ...options,
        categoryId,
      }),
    );
  };

  // Override property names to match the sort type
  // Temp
  const overridePropertyName = (sortType: string) => {
    let dataIndex: string;

    switch (sortType) {
      case 'socioEconomicScore':
        dataIndex = 'socioEcoScore';
        break;
      case 'affordabilityScore':
        dataIndex = 'affordScore';
        break;
      default:
        dataIndex = sortType;
    }

    return dataIndex;
  };

  const handleOpenSuburbReport = (suburbCode: string) => {
    dispatch(resetSuburb());
    dispatch(resetReport());
    dispatch(resetTemplate());

    dispatch(setIsReportToggleEnabled(false));
    dispatch(setIsEditedVersion(false));

    history.push(
      `/remi${!isAgencyLeadView ? '-internal' : ''}/report/${suburbCode}`,
    );
  };

  const renderBadge = (value?: string, color?: string, classNames?: string) => {
    if (color) {
      const className = color === '#FF0000' ? 'negative' : 'positive';

      return (
        <div
          className={`l-suburb-scoring__badge l-suburb-scoring__badge--${className} ${classNames}`}
        >
          {value}
        </div>
      );
    }

    return value ? (
      <div className="l-suburb-scoring__badge">{value}</div>
    ) : (
      <></>
    );
  };

  const renderClearButton = () => {
    return (
      <a
        className="l-suburb-scoring__clear-button"
        onClick={() => {
          dispatch(setQueryValues([]));
          dispatch(
            setOptions({
              ...options,
              searchMetrics: searchMetricsDefaults,
              categoryId: 0,
            }),
          );

          dispatch(setEnabledSearchMetrics([]));

          dispatch(setIsSearchFeatureLoading(true));
          fetchResults(null, true);
        }}
      >
        Clear Search
        <CloseCircleFilled style={{ color: '#888' }} />
      </a>
    );
  };

  const renderScore = (value?: number, color?: string) => {
    if (!value) {
      return <></>;
    }

    let colorMatch: string;

    switch (value) {
      case 5:
        colorMatch = '#4CAF50';
        break;
      case 4:
        colorMatch = '#A8D08D';
        break;
      case 3:
        colorMatch = '#FFEB3B';
        break;
      case 2:
        colorMatch = '#FF9800';
        break;
      default:
        colorMatch = '#F44336';
    }

    return (
      <div
        className="l-suburb-scoring__percent-circle"
        style={{
          background: `radial-gradient(closest-side, white 80%, transparent 84% 100%), conic-gradient(${colorMatch} ${
            value * 20
          }%, #f8f8f8 0)`,
        }}
      >
        <span className="l-suburb-scoring__percent-circle-value">{value}</span>
      </div>
    );
  };

  const renderNumbers = () => {
    const totalCount = results.resultCount;
    const lowerCount =
      ((options.pageIndex || 0) - 1) * results.itemsPerPage + 1;
    const upperCount = (options.pageIndex || 0) * results.itemsPerPage;

    return (
      <>
        {results.pageCount > 1 ? (
          <div className="l-suburb-scoring__numbers">
            Showing <span>{lowerCount}</span> -{' '}
            <span>{upperCount > totalCount ? totalCount : upperCount}</span> of{' '}
            <span>{totalCount}</span> results.
          </div>
        ) : (
          <div className="l-suburb-scoring__numbers">
            Showing <span>{totalCount}</span> result
            {totalCount > 1 ? 's.' : '.'}
          </div>
        )}
      </>
    );
  };

  const renderTextWithIcon = (text: any, icon: any) => {
    const style = { whiteSpace: 'nowrap' };
    if (!icon) {
      return <span>{text}</span>;
    }

    const words = text.split(' ');

    if (words.length === 1) {
      return (
        <span style={style}>
          {text} {icon}
        </span>
      );
    }

    const lastWord = words.pop();

    return (
      <>
        {words.join(' ')}{' '}
        <span style={style}>
          {lastWord} {icon}
        </span>
      </>
    );
  };

  const renderSuburbName = ({
    suburbName,
    suburbCode,
    postCode,
    state,
    categoryId,
  }) => {
    return (
      <>
        {/* @ts-ignore */}
        <Tooltip placement="top" title={!!categoryId && results?.suburbWarning}>
          <button
            className="l-suburb-scoring__suburb-name"
            onClick={() => {
              handleOpenSuburbReport(suburbCode);
            }}
          >
            {renderTextWithIcon(
              suburbName,
              <>
                {categoryId ? (
                  <WarningOutlined style={{ color: '#f00' }} />
                ) : null}
              </>,
            )}
          </button>
        </Tooltip>
        <span>
          {postCode}, {state}
        </span>
      </>
    );
  };

  const renderForm = () => {
    return (
      <div className="l-suburb-scoring__form-wrapper">
        {isFilterModalActive && (
          <FilterModal
            widerSearchValue={widerSearchValue}
            onWiderSearchChange={onWiderSearchChange}
            fetchResults={fetchResults}
            closeHandler={handleFilterModalClose}
            metricKeyValues={metricKeyValues}
          />
        )}
        {isSortModalVisible && (
          <SortModal
            fetchResults={fetchResults}
            closeHandler={handleSortModalClose}
          />
        )}
        <div className="l-suburb-scoring__form">
          <div className="l-suburb-scoring__input">
            <div className="l-suburb-scoring__input-content">
              <div className="l-suburb-scoring__input-field-wrapper">
                <div className="l-suburb-scoring__search-icon">
                  {isSuggestionsLoading ? (
                    <Spin
                      size={'small'}
                      style={{
                        height: '18px',
                        width: '18px',
                        marginBottom: '3px',
                      }}
                    />
                  ) : (
                    <Search />
                  )}
                </div>
                <div
                  className="l-suburb-scoring__selected"
                  // @ts-ignore
                  ref={selectedRef}
                  onClick={() => {
                    // @ts-ignore
                    inputRef.current.focus();
                  }}
                  role="button"
                >
                  {queryValues.map((queryValue: any) => {
                    return (
                      <div
                        className="l-suburb-scoring__overflow-item-suggestion"
                        key={queryValue.value}
                      >
                        <span>{queryValue.key}</span>
                        <strong
                          onClick={(e) => {
                            e.stopPropagation();

                            dispatch(
                              setQueryValues(
                                queryValues.filter((queryValueCurrent: any) => {
                                  return (
                                    queryValueCurrent.value !== queryValue.value
                                  );
                                }),
                              ),
                            );
                            // @ts-ignore
                            inputRef.current.focus();
                          }}
                          className="l-suburb-scoring__overflow-item-remove"
                        >
                          <Close />
                        </strong>
                      </div>
                    );
                  })}
                  <div className="l-suburb-scoring__overflow-item">
                    <span
                      className="l-suburb-scoring__phantom-text"
                      // @ts-ignore
                      ref={phantomTextRef}
                    >
                      <span>{searchKeywordDisplay}</span>
                    </span>
                    <input
                      placeholder={'State, LGA, Postcode, or Suburb'}
                      onChange={debounce(setSearchKeywordRealValue, 200)}
                      onKeyUp={() => {
                        // @ts-ignore
                        setSearchKeywordDisplay(inputRef.current.value);
                      }}
                      style={{
                        width,
                      }}
                      defaultValue={keyword}
                      ref={inputRef}
                    />
                  </div>
                </div>
              </div>

              <div className="l-suburb-scoring__button-group">
                <Button
                  type="primary"
                  className="l-suburb-scoring__primary-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchResults();
                  }}
                >
                  Search
                </Button>
                <Button
                  className="l-suburb-scoring__button"
                  onClick={() => {
                    setIsFilterModalActive(true);
                  }}
                >
                  <Filter />
                  {!!enabledSearchMetrics?.length && (
                    <span>{enabledSearchMetrics.length}</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div className="l-suburb-scoring__suggestions-anchor">
            {suggestions && !!suggestions.length && !isAllSelected && (
              <div className="l-suburb-scoring__suggestions">
                <ul className="l-suburb-scoring__suggestions-list">
                  {suggestions.map((suggestion: any) => {
                    if (
                      queryValues.some((queryValue: any) => {
                        return queryValue.value === suggestion.value;
                      })
                    ) {
                      return null;
                    }

                    return (
                      <div
                        className="l-suburb-scoring__suggestions-item"
                        key={suggestion.value}
                        onClick={() => {
                          dispatch(
                            setQueryValues([...queryValues, ...[suggestion]]),
                          );
                          dispatch(resetSuggestions());
                          dispatch(setKeyword(undefined));
                          // @ts-ignore
                          inputRef.current.focus();
                          // @ts-ignore
                          inputRef.current.value = '';
                        }}
                      >
                        <span>
                          {reactStringReplace(
                            suggestion.key,
                            keyword,
                            (match) => {
                              return <strong key={match}>{match}</strong>;
                            },
                          )}
                        </span>
                        <small className="l-suburb-scoring__bullet"></small>
                        <span className="l-suburb-scoring__desc">
                          {suggestion.desc}
                        </span>
                      </div>
                    );
                  })}
                </ul>
              </div>
            )}
            {!suggestions.length && keyword && (
              <div className="l-suburb-scoring__suggestions">
                <div className="l-suburb-scoring__suggestions-empty">
                  No suggestions found
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="l-suburb-scoring__results-wrapper">
        {renderNumbers()}
        {!isMobileViewport ? (
          <TableView
            metricKeyValues={metricKeyValues}
            fetchResults={fetchResults}
            renderScore={renderScore}
            renderBadge={renderBadge}
            renderSuburbName={renderSuburbName}
            renderTextWithIcon={renderTextWithIcon}
            overridePropertyName={overridePropertyName}
          />
        ) : (
          <AccordionView
            metricKeyValues={metricKeyValues}
            fetchResults={fetchResults}
            renderScore={renderScore}
            renderBadge={renderBadge}
            renderSuburbName={renderSuburbName}
            handleOpenSuburbReport={handleOpenSuburbReport}
            overridePropertyName={overridePropertyName}
            renderTextWithIcon={renderTextWithIcon}
          />
        )}

        {isMobileViewport ? (
          <div className="l-suburb-scoring__sticky-pagination">
            <Button
              type="primary"
              className="l-suburb-scoring__sticky-pagination-button"
              onClick={() => {
                scrollToTop();
              }}
            >
              <RemiUpArrow color="#fff" />
            </Button>
            <Pagination fetchResults={fetchResults} />
          </div>
        ) : (
          <Pagination fetchResults={fetchResults} />
        )}
      </div>
    );
  };

  const renderWiderSearch = () => {
    return (
      <div className="l-suburb-scoring__wider-search">
        <div
          style={{
            gap: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Switch
            checked={widerSearchValue}
            onChange={() => {
              onWiderSearchChange();
              fetchResults({ categoryId: !widerSearchValue });
            }}
          />
          <div
            style={{
              gap: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span className="l-suburb-scoring__wider-search-label">
              Wider Search
            </span>
            <Popover
              content={
                <button
                  className="l-descriptor"
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  {!isDesktopViewport && (
                    <button
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (!isDesktopViewport) {
                          setIsPopoverOpen({});
                        }
                      }}
                    >
                      <CloseOutlined style={{ color: '#fff' }} />
                    </button>
                  )}
                  <div style={{ textAlign: 'left' }}>
                    <WarningOutlined
                      style={{
                        color: '#e57373',
                        fontSize: '20px',
                        verticalAlign: 'middle',
                      }}
                    />{' '}
                    For more accurate insights, results only includes suburbs
                    with a minimum of 30 sales in the last 12 months and with
                    sufficient data. Turn on Wider Search to disable this
                    setting and view more results.
                  </div>
                </button>
              }
              title={null}
              placement="top"
              trigger={isDesktopViewport ? 'hover' : 'click'}
              overlayClassName="no-override"
              open={isPopoverOpen['wide-search']}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!isDesktopViewport) {
                    setIsPopoverOpen({
                      [`wide-search`]: !isPopoverOpen['wide-search'],
                    });
                  }
                }}
                style={{ display: 'flex' }}
              >
                <RemiInfo
                  style={{
                    color: '#888',
                    cursor: isDesktopViewport ? 'default' : 'pointer',
                  }}
                />
              </button>
            </Popover>
          </div>
        </div>
        <div
          style={{
            gap: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {queryValues?.length > 0 && renderClearButton()}
          <button
            className="l-suburb-scoring__sort-button"
            onClick={() => {
              setIsSortModalVisible(true);
            }}
          >
            Sort
            <RemiSort
              size="16"
              color="#888"
              style={{ transform: 'scale(0.8)' }}
            />
          </button>
        </div>
      </div>
    );
  };

  useShowleadSuccessMessage(user);

  useEffect(() => {
    if (user) {
      if (searchKeywordDisplay) {
        // @ts-ignore
        setWidth(phantomTextRef.current.offsetWidth + 100);
      } else {
        setWidth('100%');
      }
    }
  }, [searchKeywordDisplay]);

  useEffect(() => {
    if (user) {
      scrollToBottom(selectedRef);
    }
  }, [queryValues]);

  useEffect(() => {
    if (!results) {
      fetchResults();
    }

    scrollToTopmost();
  }, []);

  return (
    <>
      <div className="l-suburb-scoring">
        <div className="l-suburb-scoring__main-heading">
          {(user?.ctaPlanDetails?.goodFit === true ||
            user?.ctaPlanDetails?.goodFit === null ||
            user?.ctaPlanDetails === null) && (
            <div className="l-suburb-scoring__main-heading-actions">
              <div className="l-suburb-scoring__main-heading-cta">
                {(user?.ctaPlanDetails === null ||
                  user?.ctaPlanDetails?.goodFit === null) && (
                  <span>What&apos;s next?</span>
                )}
                <Button
                  className="l-suburb-scoring__main-heading-cta-button"
                  type="primary"
                  shape="round"
                  onClick={handleCta}
                >
                  {user?.ctaPlanDetails?.goodFit === null ||
                  user?.ctaPlanDetails === null ? (
                    <>
                      Build my Portfolio Plan <ArrowRightOutlined />
                    </>
                  ) : (
                    <>
                      Take the next step <ArrowRightOutlined />
                    </>
                  )}
                </Button>
              </div>
            </div>
          )}
          <div className="l-suburb-scoring__main-heading-text">
            <h2 id="h2-anchor">Real Estate Market Intelligence</h2>
            <h6>
              Ready to hunt for the next hotspot?
              {!isMobileViewport ? (
                <>
                  <br />
                  Simply type in a suburb or LGA below, or explore the filters.
                  <br />
                </>
              ) : (
                <> Simply use the field below. </>
              )}
              For the full Suburb Report, click on a Suburb Name.
            </h6>
            <p>
              {!isMobileViewport ? (
                <em>
                  Note: at this stage, Pathfinder covers the residential housing
                  market ONLY (ie. no units, no commercial)
                </em>
              ) : (
                <em>
                  Note: Currently, Pathfinder covers the residential market only
                  and it&rsquo;s best viewed on desktop.
                </em>
              )}
            </p>
          </div>
        </div>
        {isSearchFeatureLoading && (
          <FullPageLoader classNames="l-suburb-scoring__page-loader" />
        )}

        {renderForm()}
        {!(!results && options.categoryId === 0)
          ? renderWiderSearch()
          : renderClearButton()}
        {results && <>{renderResults()}</>}

        {!isSearchFeatureLoading && !results && options.categoryId === 1 && (
          <div className="l-suburb-scoring__no-result-container">
            <h4 style={{ paddingBottom: 10 }}>No Results Found</h4>
          </div>
        )}

        {!isSearchFeatureLoading && !results && options.categoryId === 0 && (
          <div
            style={{
              maxWidth: '800px',
              width: '100%',
              margin: '20px',
            }}
          >
            <Result
              status={'warning'}
              title="No Results Found"
              subTitle={
                <div style={{ margin: '30px 0 30px' }}>
                  <p
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      marginBottom: '30px',
                      color: '#232e40',
                      lineHeight: '1.6',
                    }}
                  >
                    Default filters show results for locations with a minimum of
                    30 sales over the last 12 months and exclude suburbs with
                    insiffcient data to be statistically vibable. To possibly
                    find this location, would you like to widen the search by
                    removing these default filters?
                  </p>

                  <div className="l-suburb-scoring__no-result-wider-container">
                    <span className="l-suburb-scoring__no-result-wider-label">
                      Wider Search
                    </span>
                    <Switch
                      checked={widerSearchValue}
                      onChange={() => {
                        onWiderSearchChange();
                        fetchResults({ categoryId: 1 });
                      }}
                    />
                  </div>
                </div>
              }
            />
          </div>
        )}
      </div>
      {isAgencyLeadView && <Footer />}
    </>
  );
};

export default SuburbScoring;
