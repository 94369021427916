import { Menu, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RouteType, useRoutes } from '../../../foundation/config/routes';
import { useRole } from '../../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import LearnMore from '../../lead/learn_more/LearnMore';
import CreateOffsetAccountModal from '../../offset_account/create_offset_account_modal/CreateOffsetAccountModal';
import { selectPlanInputs } from '../../plan/redux/selectors';
import CreatePropertyModal from '../../property/create_property_modal/CreatePropertyModal';
import {
  selectedPlanOffsetAccounts,
  selectedPlanRecords,
  selectPlanProperties,
} from '../../property/redux/selectors';
import { setSelectedPropertyId } from '../../property/redux/slice';

const { SubMenu } = Menu;

const SiderContent = ({
  routeConfig,
  handleMobileMenuClose,
}: {
  routeConfig: RouteType | undefined;
  handleMobileMenuClose?: () => void;
}) => {
  const { isDesktopViewport, isMobileViewport } = useViewport();
  const history = useHistory();

  const [, , , , , isAgencyLeadView] = useRole();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOffsetModalVisible, setIsOffsetModalVisible] = useState(false);

  const [selectedKeys, setSelectedKeys] = useState([history.location.pathname]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const planProperties = useSelector(selectPlanProperties);

  const planOffsetAccounts = useSelector(selectedPlanOffsetAccounts);

  const planRecords = useSelector(selectedPlanRecords);

  const planInputs = useSelector(selectPlanInputs);

  const [isClientView] = useRole();

  const routes = useRoutes(
    planProperties,
    isClientView,
    planOffsetAccounts,
    planRecords,
  );

  const dispatch = useDispatch();

  const handleModalClose = () => {
    setIsModalVisible(false);
    setIsOffsetModalVisible(false);
  };

  const renderSubMenu = (
    item: any,
    items: React.ReactNode[],
    isMobileProperty,
  ) => {
    return (
      <SubMenu
        className={`pgp-sub-menu ${isMobileProperty ? ' pgp-sub-menu--property-card' : ''}`}
        key={`${item.completePath}`}
        icon={item.icon ? <item.icon /> : null}
        title={
          item.data?.isSold ? (
            <span>
              <span className="c-app-layout__sold-indicator">S</span>
              {item.name}
            </span>
          ) : (
            item.name
          )
        }
      >
        {items}
      </SubMenu>
    );
  };

  const menuRenderer = (item: any) => {
    if (!item.isSubMenu) {
      return (
        <Menu.Item
          className="pgp-menu-item"
          key={`${item.completePath}`}
          icon={item.icon ? <item.icon /> : null}
        >
          {item.name}
        </Menu.Item>
      );
    } else {
      const items: React.ReactNode[] = [];

      for (const route of item.routes) {
        if (!route.hideInMenu) {
          items.push(menuRenderer(route));
        }
      }

      const isMobileProperty =
        (!isDesktopViewport && item.data?.isProperty) || false;

      return renderSubMenu(item, items, isMobileProperty);
    }
  };

  const menuItems = useMemo(() => {
    const items: any = [];

    for (const item of routes) {
      if (!item.hideInMenu) {
        items.push(menuRenderer(item));
      }
    }

    return items;
  }, [routes]);

  useEffect(() => {
    setSelectedKeys([history.location.pathname]);
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(setSelectedPropertyId(routeConfig?.data?.propertyId));
  }, [routeConfig]);

  const handleMenuSelect = ({ key }) => {
    if (key === '/properties/new') {
      if (!planInputs?.cashAvailable) {
        notification.info({
          message: 'Info',
          description: 'Please update Plan Inputs before creating a property.',
        });
      } else {
        setIsModalVisible(true);
      }
    } else if (key === '/oa-transactions/new') {
      // Create new Offset Account Page.
      setIsOffsetModalVisible(true);
    } else {
      history.push(key);
      setSelectedKeys([key]);
      if (handleMobileMenuClose) {
        handleMobileMenuClose();
      }
    }
  };

  return (
    <>
      {isModalVisible && <CreatePropertyModal closeModal={handleModalClose} />}
      {isOffsetModalVisible && (
        <CreateOffsetAccountModal closeModal={handleModalClose} />
      )}
      <Menu
        style={{
          marginBottom: isMobileViewport ? '50px' : '0',
        }}
        mode="inline"
        //   theme={theme}
        selectedKeys={selectedKeys}
        defaultOpenKeys={openKeys}
        openKeys={openKeys}
        onSelect={handleMenuSelect}
        onOpenChange={(keys) => {
          setOpenKeys(keys);
        }}
      >
        {menuItems}
      </Menu>
      {isAgencyLeadView && isDesktopViewport && <LearnMore />}
    </>
  );
};

export default SiderContent;
