import { Button, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Close from '../../../foundation/assets/svgs/Close';
import Select from '../../../foundation/components/select/Select';
import { KeyValue } from '../../../foundation/types';
import { selectClient } from '../../client/redux/selectors';
import {
  selectLastVisitedPlanId,
  selectPlan,
} from '../../plan/redux/selectors';
import { setLastVisitedPlanId, setPlan } from '../../plan/redux/slice';
import { resetPlanProperties } from '../../property/redux/slice';

type SelectPlanModalProps = {
  closeModal: () => void;
};

const SelectPlanModal = ({ closeModal }: SelectPlanModalProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const client = useSelector(selectClient);
  const plan = useSelector(selectPlan);
  const lastVisitedPlanId = useSelector(selectLastVisitedPlanId);

  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>(
    plan?.planId,
  );

  const handleModalClose = () => {
    closeModal();
  };

  const planOptions = useMemo(() => {
    const options: KeyValue[] = [];
    if (client?.planIds && client.planIds.length > 0) {
      for (const item of client.planIds) {
        options.push({
          key: item.planName,
          value: item.planId,
        });
      }
    }
    return options;
  }, [client?.planIds]);

  const modalOpts = {
    title: 'Portfolio Plan',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
    width: 350,
  };

  const handlePlanSelection = useCallback((v: string) => {
    setSelectedPlanId(v);
  }, []);

  const renderPlanSelector = () => {
    return (
      <>
        {planOptions && planOptions.length > 0 && (
          <>
            <div>
              <label>
                <strong>Select Plan To View:</strong>
              </label>
              <Select
                options={planOptions}
                value={selectedPlanId}
                onChange={handlePlanSelection}
                style={{ width: '100%' }}
              />
            </div>
            <Button
              style={{ width: '100%', marginTop: '20px', fontSize: '15px' }}
              type="primary"
              size="large"
              onClick={() => {
                if (selectedPlanId) {
                  dispatch(setLastVisitedPlanId(selectedPlanId));
                }
              }}
            >
              Continue
            </Button>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (selectedPlanId !== plan?.planId) {
      closeModal();
      dispatch(setPlan(undefined));
      dispatch(resetPlanProperties());
      history.push('/portfolio-growth-plan');
    }
  }, [lastVisitedPlanId]);

  return (
    <Modal {...modalOpts}>
      <div>{renderPlanSelector()}</div>
    </Modal>
  );
};

export default SelectPlanModal;
