import { Button, Input } from 'antd';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import Logo from '../../../foundation/assets/svgs/Logo.svg';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import AuthenticationFooter from '../elements/AuthenticationFooter';
import AuthenticationPageImage from '../elements/AuthenticationPageImage';
import DeleteAccountSuccessModal from '../modals/DeleteAcccountSuccessModal';
import { deleteAccount } from '../redux/async_thunks';
import { deleteAccountValidationSchema } from '../validations/delete_account_validation';

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const [inputs, setInputs] = useState<HTMLElement[]>([]);

  const { deleteCode } = useParams<{ deleteCode: string }>();

  const handleSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const data = {
        otp: values.otp,
        deleteCode: deleteCode,
      };

      // @ts-ignore
      await dispatch(deleteAccount({ data: data })).unwrap();
      setIsLoading(false);
      setIsSuccessModalVisible(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleLoginClick = () => {
    history.push('/login');
  };

  useEffect(() => {
    if (!deleteCode) {
      history.push('/login');
    }
  });

  useEffect(() => {
    const inputElements = Array.from(document.querySelectorAll('input'));

    inputElements.forEach((input, index) => {
      input.setAttribute('data-id', `input-${index}`);
      input.setAttribute('inputmode', 'numeric');
    });

    setInputs(inputElements);
  }, []);

  return (
    <div className="client-dash-login">
      {isLoading && <FullPageLoader />}
      {isSuccessModalVisible && <DeleteAccountSuccessModal />}
      <div className="client-dash-login__form-wrapper">
        <div className="client-dash-login__logo-container">
          <Logo />
        </div>
        <Formik
          initialValues={{
            otp: undefined,
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={deleteAccountValidationSchema}
        >
          {({
            errors,
            values,
            handleSubmit,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
          }) => {
            return (
              <form onSubmit={handleSubmit} className="client-dash-login__form">
                <div className="client-dash-login__form-heading">
                  Delete Account
                </div>

                <div className="c-form-field">
                  <div
                    className="c-form-field__label"
                    style={{ marginBottom: '6px' }}
                  >
                    Enter the 6-digit one time password:
                  </div>
                  <div className="c-form-field__wrapper">
                    <Input.OTP
                      inputMode="numeric"
                      length={6}
                      style={{ width: '100%', fontSize: '20px' }}
                      size="large"
                      onChange={(value) => {
                        setFieldValue('otp', value);
                      }}
                      onInput={(e: any) => {
                        if (!e?.target?.value) {
                          setFieldValue('otp', undefined);
                        }
                      }}
                      value={values.otp}
                      onBlur={(e: any) => {
                        const inputElement = e.target as HTMLInputElement;
                        const value = inputElement.value;
                        const currentId =
                          inputElement.getAttribute('data-id') || '';

                        if (!/^\d*$/.test(value)) {
                          const currentIndex = inputs.findIndex(
                            (input) =>
                              input.getAttribute('data-id') === currentId,
                          );

                          (inputs[currentIndex] as HTMLElement).focus();
                        }

                        setFieldTouched('otp', true);
                      }}
                      formatter={(value) => {
                        return value.replace(/[^0-9]/g, '');
                      }}
                    />
                    <div className="c-form-field__error">
                      {errors.otp ? errors.otp : undefined}
                    </div>
                  </div>
                </div>

                <div className="c-form__btn-wrapper">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="client-dash-login__form-btn"
                    disabled={!(isValid && dirty) || isLoading}
                  >
                    Proceed Delete
                  </Button>
                </div>

                <div className="client-dash-login__link-ref">
                  Changed your mind?
                  <a
                    className="client-dash-login__link"
                    onClick={handleLoginClick}
                  >
                    Log in
                  </a>
                </div>
              </form>
            );
          }}
        </Formik>
        <AuthenticationFooter />
      </div>

      <AuthenticationPageImage />
    </div>
  );
};

export default DeleteAccount;
