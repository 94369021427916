import 'chartjs-adapter-moment';

import { Card, Row } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import BubbleChart from '../../../foundation/components/bubble_chart/BubbleChart';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import {
  selectDashboardPurchaseRoadmap,
  selectDashboardPurchaseRoadmapAxisData,
} from '../redux/selectors';

const PurchaseRoadmapSection = () => {
  const performaceGraphData = useSelector(selectDashboardPurchaseRoadmap);
  const {
    isMobileViewport,
    isTabletViewport,
    isDesktopViewport,
    isLargeDesktopViewport,
    isVeryLargeDesktopViewport,
    isLargeTabletViewport,
  } = useViewport();

  const performaceGraphAxisData = useSelector(
    selectDashboardPurchaseRoadmapAxisData,
  );

  const computeBubbleSize = (bubbleSize: number | undefined) => {
    if (!bubbleSize) {
      return 0;
    }

    const factor = isTabletViewport ? 0.66 : isMobileViewport ? 0.33 : 1;

    return bubbleSize * factor;
  };

  /**
   * X-axis labels.
   */
  const labels = useMemo(() => {
    return performaceGraphData
      ? performaceGraphData.map((item) => moment(item.date))
      : [];
  }, [performaceGraphData]);

  const svgSize = useMemo(() => {
    if (isVeryLargeDesktopViewport) {
      return 60;
    } else if (isLargeDesktopViewport) {
      return 50;
    } else if (isLargeTabletViewport) {
      return 40;
    } else if (isDesktopViewport) {
      return 43;
    } else {
      return 32;
    }
  }, [
    isTabletViewport,
    isMobileViewport,
    isDesktopViewport,
    isLargeTabletViewport,
    isVeryLargeDesktopViewport,
    isLargeDesktopViewport,
  ]);

  const cashflowSvg = useMemo(
    () => `
  <svg width="${svgSize}" height="${svgSize}" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#D6F7F480"/>
    <path d="M14 22.6667V16H18V22.6667M10 14L16 9.33337L22 14V21.3334C22 21.687 21.8595 22.0261 21.6095 22.2762C21.3594 22.5262 21.0203 22.6667 20.6667 22.6667H11.3333C10.9797 22.6667 10.6406 22.5262 10.3905 22.2762C10.1405 22.0261 10 21.687 10 21.3334V14Z" stroke="#019386" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
    [],
  );

  const growthSvg = useMemo(
    () => `
    <svg width="${svgSize}" height="${svgSize}" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#E0EAFF80"/>
    <path d="M14 22.6667V16H18V22.6667M10 14L16 9.33337L22 14V21.3334C22 21.687 21.8595 22.0261 21.6095 22.2762C21.3594 22.5262 21.0203 22.6667 20.6667 22.6667H11.3333C10.9797 22.6667 10.6406 22.5262 10.3905 22.2762C10.1405 22.0261 10 21.687 10 21.3334V14Z" stroke="#444CE7" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    [],
  );

  const balancedSvg = useMemo(
    () => `
  <svg width="${svgSize}" height="${svgSize}" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="32" height="32" rx="16" fill="#FEF0C780"/>
  <path d="M14 22.6667V16H18V22.6667M10 14L16 9.33337L22 14V21.3334C22 21.687 21.8595 22.0261 21.6095 22.2762C21.3594 22.5262 21.0203 22.6667 20.6667 22.6667H11.3333C10.9797 22.6667 10.6406 22.5262 10.3905 22.2762C10.1405 22.0261 10 21.687 10 21.3334V14Z" stroke="#DC6803" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `,
    [],
  );

  const datasets = useMemo(() => {
    const sets: any = [];

    if (performaceGraphData && performaceGraphAxisData) {
      const keys = Object.keys(performaceGraphData[0]);

      for (const key of keys) {
        if (
          key !== 'date' &&
          key !== 'bubblesize' &&
          key !== 'requiredamount' &&
          key !== 'propertyname'
        ) {
          const graphData: any = [];
          for (const i of performaceGraphData) {
            if (i[key] !== 0) {
              if (isMobileViewport) {
                graphData.push({
                  x: i[key],
                  y: moment(i.date),
                  r: computeBubbleSize(i.bubblesize),
                  amount: i.requiredamount,
                  propertyName: i.propertyname,
                });
              } else {
                graphData.push({
                  y: i[key],
                  x: moment(i.date),
                  r: computeBubbleSize(i.bubblesize),
                  amount: i.requiredamount,
                  propertyName: i.propertyname,
                });
              }
            }
          }

          let axisData: any = null;

          for (const i of performaceGraphAxisData) {
            if (i.dataName === key) {
              axisData = i;
              break;
            }
          }
          sets.push({
            label: `      ${axisData?.label}`,
            data: graphData,
            segment: {},
            color: '#475467',
            pointStyle: new window.Image(),
            key: axisData.label,
          });

          // After creating the 'sets' array, but before returning it:
          sets.forEach((set, index) => {
            switch (set.key) {
              case 'Balanced': {
                set.pointStyle.src =
                  'data:image/svg+xml;base64,' + btoa(balancedSvg);
                break;
              }
              case 'Cash Flow': {
                set.pointStyle.src =
                  'data:image/svg+xml;base64,' + btoa(cashflowSvg);
                break;
              }
              case 'Growth': {
                set.pointStyle.src =
                  'data:image/svg+xml;base64,' + btoa(growthSvg);
                break;
              }
              default: {
                set.pointStyle.src =
                  'data:image/svg+xml;base64,' + btoa(growthSvg);
                break;
              }
            }
          });
        }
      }
    }

    // Sort the 'sets' array, handling undefined keys
    const order = ['Growth', 'Balanced', 'Cash Flow'];
    sets.sort((a, b) => {
      const indexA = a.key ? order.indexOf(a.key) : -1; // -1 if key is undefined
      const indexB = b.key ? order.indexOf(b.key) : -1;

      if (indexA === -1 && indexB === -1) {
        return 0; // Maintain original order if both keys are undefined
      } else if (indexA === -1) {
        return 1; // Place undefined keys at the end
      } else if (indexB === -1) {
        return -1;
      } else {
        return indexA - indexB; // Sort based on the index in 'order'
      }
    });

    return sets;
  }, [performaceGraphData, isTabletViewport, isMobileViewport]);

  /**
   * Formatted data used by the line graph.
   */
  const data = {
    labels,
    datasets: datasets,
  };

  return (
    <Row className="dashboard__section dashboard__annual-cashflow-section">
      <Card
        className="dashboard__card dashboard__card--no-hover"
        hoverable={false}
      >
        <div className="dashboard__header">
          <div className="dashboard__header-title dashboard__header-title--medium">
            Property Purchase Roadmap
          </div>
        </div>
        <div className="dashboard__chart-wrapper">
          <section className="dashboard__chart-content dashboard__chart-content--first">
            <BubbleChart
              xAxesLabel="Graph Timeline"
              yAxesLabel="Amount"
              data={data}
              graphTitle="Purchase Roadmap"
              areaAboveFill="rgba(0, 178, 163,0.2)"
              xAxesUnit="month"
              xAxesType="time"
              titleMomentFormat="MMM DD, YYYY"
            />
          </section>
        </div>
      </Card>
    </Row>
  );
};

export default PurchaseRoadmapSection;
