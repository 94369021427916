import { Button, Radio } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import {
  selectIsSearchFeatureLoading,
  selectOptions,
  selectSearchMetricValues,
} from '../redux/selectors';
import { setOptions } from '../redux/slice';

type SortProps = {
  fetchResults: any;
  closeHandler: any;
};

const Sort = ({ fetchResults, closeHandler }: SortProps) => {
  const RadioGroup = Radio.Group;
  const dispatch = useDispatch();

  const options = useSelector(selectOptions);
  const searchMetricValues = useSelector(selectSearchMetricValues);
  const isSearchFeatureLoading = useSelector(selectIsSearchFeatureLoading);

  const [newOptions, setNewOptions] = React.useState<any>(options);

  const sortTypeOptions = searchMetricValues?.sortTypes.map((option: any) => {
    return {
      label: option.key,
      value: option.value,
    };
  });

  const sortTypeChangeHandler = (e: any) => {
    setNewOptions({
      ...options,
      ...newOptions,
      sortType: e.target.value,
    });
  };

  const orderChangeHandler = (e: any) => {
    setNewOptions({
      ...options,
      ...newOptions,
      orderAsc: e.target.value,
    });
  };

  return (
    <>
      {isSearchFeatureLoading && <FullPageLoader />}
      <div className="l-sorting">
        <div className="l-sorting__content">
          <div className="l-sorting__control">
            <RadioGroup
              options={sortTypeOptions}
              optionType="default"
              value={newOptions ? newOptions.sortType : null}
              onChange={sortTypeChangeHandler}
            />
          </div>
          <hr className="l-sorting__divider" />
          <div className="l-sorting__control">
            <RadioGroup
              options={[
                {
                  label: 'Ascending / A-Z',
                  value: true,
                },
                {
                  label: 'Descending / Z-A',
                  value: false,
                },
              ]}
              optionType="default"
              value={newOptions.orderAsc}
              onChange={orderChangeHandler}
            />
          </div>
        </div>
        <div className="l-sorting__footer">
          <div className="l-sorting__button-group">
            <Button
              type="primary"
              style={{ marginLeft: 'auto', width: '200px' }}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setOptions(newOptions));
                fetchResults(newOptions);
                closeHandler();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sort;
