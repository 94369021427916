import 'chartjs-adapter-moment';

import { Card, Row } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LineGraph from '../../../foundation/components/line_graph/LineGraph';
import { selectDashboardEquityForecast } from '../redux/selectors';

const EquityGrowthSection = () => {
  const equityGraphData = useSelector(selectDashboardEquityForecast);

  /**
   * X-axis labels.
   */
  const labels = useMemo(() => {
    return equityGraphData
      ? equityGraphData.map((item) => moment(item.timeline))
      : [];
  }, [equityGraphData]);

  /**
   * Formatted data for net cashflow graph.
   */
  const graphData = useMemo(() => {
    return equityGraphData
      ? equityGraphData.map((item) => ({
          y: item.availableEquity,
          x: moment(item.timeline),
        }))
      : [];
  }, [equityGraphData]);

  const graphPortfolioData = useMemo(() => {
    return equityGraphData
      ? equityGraphData.map((item) => ({
          y: item.portfolioValue,
          x: moment(item.timeline),
        }))
      : [];
  }, [equityGraphData]);

  const graphTotalEquityData = useMemo(() => {
    return equityGraphData
      ? equityGraphData.map((item) => ({
          y: item.totalEquity,
          x: moment(item.timeline),
        }))
      : [];
  }, [equityGraphData]);

  /**
   * Formatted data used by the line graph.
   */
  const data = {
    labels,
    datasets: [
      {
        label: 'Portfolio Value',
        data: graphPortfolioData,
        segment: {},
        borderColor: '#0086C9',
        backgroundColor: '#0086C9',
        color: 'rgba(0,0,0,1)',
        pointBorderColor: 'transparent',
        pointRadius: 1,
        pointStyle: 'circle',
        fill: {
          target: '+1',
          below: 'rgba(255, 77, 79, 0.2)', // Area will be red above the origin
          above: '#E0F2FE99',
        },
      },
      {
        label: 'Total Equity',
        data: graphTotalEquityData,
        segment: {},
        borderColor: '#F79009',
        backgroundColor: '#F79009',
        color: 'rgba(0,0,0,1)',
        pointBorderColor: 'transparent',
        pointRadius: 1,
        fill: {
          target: '+1',
          below: 'rgba(255, 77, 79, 0.5)', // Reduced opacity for 'below' fill
          above: 'rgba(255, 220, 171, 0.5)',
        },
      },
      {
        label: 'Available Equity (@LVR)',
        data: graphData,
        segment: {},
        borderColor: '#32D583',
        backgroundColor: '#32D583',
        color: 'rgba(0,0,0,1)',
        pointBorderColor: 'transparent',
        pointRadius: 1,
        fill: {
          target: 'origin',
          below: 'rgba(255, 77, 79, 0.2)', // Area will be red above the origin
          above: '#D1FADF',
        },
      },
    ],
  };

  return (
    <Row className="equity-forecast">
      <Card className="dashboard__card dashboard__card--no-hover">
        <div className="dashboard__header">
          <div className="dashboard__header-title dashboard__header-title--medium">
            Equity Growth
          </div>
        </div>
        <div className="dashboard__chart-wrapper">
          <section className="dashboard__chart-content">
            <LineGraph
              xAxesLabel="Graph Timeline"
              yAxesLabel="Equity"
              data={data}
              graphTitle="Equity Growth Timeline"
              areaAboveFill="rgba(255, 179, 25, 0.2)"
              displayLegend={true}
            />
          </section>
        </div>
      </Card>
    </Row>
  );
};

export default EquityGrowthSection;
