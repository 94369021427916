import {
  BarChartOutlined,
  HomeOutlined,
  InsertRowAboveOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Dropdown } from 'antd';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useRole } from '../../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import {
  clearStorage,
  getItemFromStorage,
  removeItemToStorage,
} from '../../../foundation/utils/storageHandler';
import { useAppDispatch } from '../../../store/hooks';
import FavoriteReportsModal from '../../ai_report/modals/FavoriteReportsModal';
import { selectFavoriteReports } from '../../ai_report/redux/selectors';
import { logout } from '../../authentication/redux/async_thunks';
import { selectUser } from '../../authentication/redux/selectors';
import { setClient } from '../../client/redux/slice';
import { selectEnterprise } from '../../enterprise/redux/selectors';
import { setPlan } from '../../plan/redux/slice';
import ChangePassword from '../../profile/change_password/ChangePassword';
import Profile from '../../profile/Profile';
import { selectProfile } from '../../profile/redux/selectors';
import { setNavIndex } from '../../profile/redux/slice';

const ProfileDropdown = ({
  homeClick,
  scoringClick,
  portfolioGrowthPlanClick,
  leadClick,
  homeNavDisplayConfig,
}: any) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [
    ,
    isEnterpriseAdminView,
    ,
    isAgencyAdminView,
    isAgencyUserView,
    isAgencyLeadView,
  ] = useRole();

  const {
    isDesktopViewport,
    isMobileViewport,
    isTabletViewport,
    isLargeTabletViewport,
  } = useViewport();

  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  const [isFavoriteModalVisible, setIsFavoriteModalVisible] = useState(false);

  const [isChangePassModalVisible, setIsChangePassModalVisible] =
    useState(false);

  const user = useSelector(selectUser);

  const profile = useSelector(selectProfile);

  const enterprise = useSelector(selectEnterprise);

  const favoriteReports = useSelector(selectFavoriteReports);

  const clearClientPlanSelections = () => {
    /**
     * Remove values for client and plan.
     */
    removeItemToStorage('client');
    removeItemToStorage('plan');
    dispatch(setClient(undefined));
    dispatch(setPlan(undefined));
  };

  const handleLogout = async () => {
    const getUser = getItemFromStorage('user');
    // @ts-ignore
    const userStorage = JSON.parse(getUser);
    const fixUser = user || userStorage;

    try {
      if (fixUser) {
        const data: any = {
          email: profile?.email,
          userId: fixUser?.user_id,
        };

        await dispatch(
          logout({
            token: fixUser?.token,
            data,
          }),
        ).unwrap();
        clearStorage();

        window.location.href = '/';
      }
    } catch (error) {
      console.log(error);
    }
  };

  const InfoView = useMemo(() => {
    return (
      <div className="c-app-layout__profile-dd-info-wrapper">
        <div className="c-app-layout__profile-dd-info-avatar">
          <Badge dot color="#12B76A">
            <Avatar
              src={profile?.picture}
              icon={<UserOutlined />}
              size={'large'}
            />
          </Badge>
        </div>
        <div className="c-app-layout__profile-dd-info-text-wrapper">
          <div className="c-app-layout__profile-dd-info-text-name">
            {profile?.name}
          </div>
          <div className="c-app-layout__profile-dd-info-text-email">
            {profile?.email}
          </div>
        </div>
      </div>
    );
  }, [profile]);

  const handleViewProfileClick = () => {
    setIsProfileModalVisible(true);
  };

  const handleSupportClick = () => {
    clearClientPlanSelections();
    dispatch(setNavIndex(-1));
    history.push('/contact-us');
  };

  const handleEnterpriseSettingsClick = () => {
    dispatch(setNavIndex(-1));
    history.push('/enterprise-settings');
  };

  const handleAgencySettingsClick = () => {
    if (user) {
      clearClientPlanSelections();
      dispatch(setNavIndex(-1));

      // Show client selection page.
      history.push(`/agency/${user.agency_id}`);
    }
  };

  const handleViewFavoriteReportsClick = () => {
    setIsFavoriteModalVisible(true);
  };

  const menu = useMemo(() => {
    const menuItems: any = [
      {
        key: '1',
        label: InfoView,
      },
      {
        key: '2',
        onClick: handleViewProfileClick,
        label: 'View profile',
      },
    ];

    if (isEnterpriseAdminView && enterprise) {
      menuItems.push({
        key: '2.2',
        onClick: handleEnterpriseSettingsClick,
        label: 'Enterprise settings',
      });
    }

    if (isAgencyAdminView) {
      menuItems.push({
        key: '2.4',
        onClick: handleAgencySettingsClick,
        label: 'Agency settings',
      });
    }

    if ((isAgencyAdminView || isAgencyUserView) && favoriteReports?.length) {
      menuItems.push({
        key: '2.6',
        onClick: handleViewFavoriteReportsClick,
        label: 'Favorite suburb reports',
      });
    }

    menuItems.push({
      key: '3',
      onClick: handleSupportClick,
      label: 'Support',
    });

    menuItems.push({
      key: '4',
      onClick: handleLogout,
      label: 'Log out',
    });

    return menuItems;
  }, [profile, user, enterprise, isEnterpriseAdminView, favoriteReports]);

  const mobileMenu = useMemo(() => {
    const menuItems: any = [
      {
        key: '1',
        onClick: leadClick,
        label: 'Home',
      },
      {
        key: '2',
        onClick: scoringClick,
        label: 'REMi',
      },
      {
        key: '3',
        onClick: portfolioGrowthPlanClick,
        label: 'Portfolio Growth Plan',
      },
    ];

    return menuItems;
  }, [profile, user, enterprise, isEnterpriseAdminView, favoriteReports]);

  const handleProfileModalClose = () => {
    setIsProfileModalVisible(false);
  };

  const handleChangePassModalClose = () => {
    setIsChangePassModalVisible(false);
  };

  const handleFavoriteModalClose = () => {
    setIsFavoriteModalVisible(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      {isChangePassModalVisible && (
        <ChangePassword closeModal={handleChangePassModalClose} />
      )}
      {isProfileModalVisible && (
        <Profile
          closeModal={handleProfileModalClose}
          showChangePasswordModal={() => setIsChangePassModalVisible(true)}
          handleLogout={handleLogout}
        />
      )}
      {isFavoriteModalVisible && (
        <FavoriteReportsModal closeModal={handleFavoriteModalClose} />
      )}
      {((isAgencyLeadView && (isTabletViewport || isLargeTabletViewport)) ||
        (!isAgencyLeadView && !isDesktopViewport)) && (
        <div className="c-app-layout__header-nav-small">
          {!isAgencyLeadView && (
            <button onClick={homeClick}>
              <Avatar
                className="h-color--dark"
                icon={
                  homeNavDisplayConfig
                    ? homeNavDisplayConfig[user?.role_id ?? '']?.displayIcon
                    : null
                }
              />
            </button>
          )}
          {isAgencyLeadView && (
            <button onClick={leadClick}>
              <Avatar className="h-color--dark" icon={<HomeOutlined />} />
            </button>
          )}
          {(isAgencyAdminView || isAgencyUserView || isAgencyLeadView) && (
            <button onClick={scoringClick}>
              <Avatar
                className="h-color--dark"
                icon={<InsertRowAboveOutlined />}
              />
            </button>
          )}
          {isAgencyLeadView && (
            <button onClick={portfolioGrowthPlanClick}>
              <Avatar className="h-color--dark" icon={<BarChartOutlined />} />
            </button>
          )}
        </div>
      )}
      {isMobileViewport && isAgencyLeadView && (
        <Dropdown
          menu={{ items: mobileMenu }}
          className="c-app-layout__header-user-dd"
          overlayClassName="c-app-layout__header-user-dd"
        >
          <div
            className="c-app-layout__header-user"
            style={{ marginRight: '5px' }}
          >
            <div className="c-app-layout__header-user-avatar">
              <Avatar className="h-color--dark" icon={<MenuOutlined />} />
            </div>
          </div>
        </Dropdown>
      )}
      <Dropdown
        menu={{ items: menu }}
        className="c-app-layout__header-user-dd"
        overlayClassName="c-app-layout__header-user-dd"
      >
        <div className="c-app-layout__header-user">
          {!isTabletViewport && !isMobileViewport && (
            <div className="c-app-layout__header-user-name">
              {profile?.name}
            </div>
          )}
          <div className="c-app-layout__header-user-avatar">
            <Avatar
              className="h-color--dark"
              src={profile?.picture}
              icon={<UserOutlined />}
            />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default ProfileDropdown;
