import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import React from 'react';

import Close from '../../../foundation/assets/svgs/Close';

type InterruptModalProps = {
  closeModal: () => void;
  reloadTypeform: () => void;
  isFailedInitial: boolean;
};

const InterruptModal = ({
  closeModal,
  reloadTypeform,
  isFailedInitial,
}: InterruptModalProps) => {
  const handleModalClose = () => {
    closeModal();
  };

  const modalOpts = {
    header: null,
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: false,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
    width: isFailedInitial ? 440 : 300,
  };

  return (
    <Modal {...modalOpts}>
      {!isFailedInitial && (
        <div className="l-portfolio-growth-plan__modal">
          <p className="l-portfolio-growth-plan__modal-load-text">
            Evaluating financial snapshot.
          </p>
          <p className="l-portfolio-growth-plan__modal-load-text">
            Please wait a moment.
          </p>
          <div style={{ padding: '30px 30px 25px' }}>
            <Spin size="large" />
          </div>
        </div>
      )}
      {isFailedInitial && (
        <div className="l-portfolio-growth-plan__modal">
          <InfoCircleOutlined
            style={{
              fontSize: '60px',
              margin: '20px 0 30px',
              color: '#00b2a3',
            }}
          />
          <p className="l-portfolio-growth-plan__modal-text">
            Sorry, based on{' '}
            <span className="l-portfolio-growth-plan__brand">
              Property Pathfinder
            </span>{' '}
            calculations, it looks like you might need a bit more to start
            building your property portfolio.
          </p>
          <p className="l-portfolio-growth-plan__modal-text">
            Please take a moment to adjust your available funds or annual
            savings, and give it another go.
          </p>
          <div
            className="l-portfolio-growth-plan__enter-button"
            style={{
              padding: 0,
              minHeight: '50px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
          >
            <button
              style={{ backgroundColor: '#00b2a3', textTransform: 'none' }}
              onClick={() => {
                reloadTypeform();
              }}
            >
              START OVER
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default InterruptModal;
