import PropTypes from 'prop-types';
import React from 'react';
import { Route, useHistory } from 'react-router-dom';

import { getItemFromStorage } from '../../../foundation/utils/storageHandler';
// import env_constants from '../../../internals/env/env_constants.json';

// Typescript Props
type Props = {
  component: any;
};

const PrivateRoute = ({
  component: Component,
  ...rest
}: Props): JSX.Element => {
  const history = useHistory();

  /*
   * A workaround that checks a target route, store a parameter,
   * then after authentication, use that parameter to do a redirect.
   * Refactor later & make it configurable/reusable across many routes.
   */
  const checkRedirect = () => {
    const { computedMatch }: any = rest;
    const targetRoute = '/remi/shared-report';
    const storeKey = 'report_code';

    sessionStorage.removeItem(storeKey);

    if (computedMatch?.url?.startsWith(targetRoute)) {
      const reportCodeParam = computedMatch.url.split('/').pop();
      sessionStorage.setItem(storeKey, reportCodeParam);
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!getItemFromStorage('user')) {
          checkRedirect();
          history.push('/login');
        }
        return <Component {...props} />;
      }}
    />
  );
};

// PropTypes
PrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
  rest: PropTypes.any,
  path: PropTypes.any,
  strict: PropTypes.bool,
  exact: PropTypes.bool,
};

export default PrivateRoute;
