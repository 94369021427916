import React from 'react';

function PauseButton({
  color = 'white',
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      className={className}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="1" width="4" height="16" fill={color} />
      <rect x="10" y="1" width="4" height="16" fill={color} />
    </svg>
  );
}

export default PauseButton;
