import React from 'react';

const RemiOpenReport = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833374 9.16671L9.16671 0.833374M9.16671 0.833374H0.833374M9.16671 0.833374V9.16671"
        stroke="#019386"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RemiOpenReport;
