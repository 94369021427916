import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useRole } from '../../../foundation/cutom_hooks/useRole';
import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageFormatter,
} from '../../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import { updateLeverageCash } from '../redux/async_thunks';
import { selectPurchaseScenarioLeverageCash } from '../redux/selectors';
import LeverageCashModal from './LeverageCashModal';
import StatusComponent from './StatusComponent';
import { leverageCashValidationSchema } from './validations';

type Props = {
  isLoading: boolean;
  propertyId: string;
};

const LeverageCash = ({ isLoading, propertyId }: Props) => {
  const dispatch = useAppDispatch();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState(undefined);

  const dataSource = useSelector(selectPurchaseScenarioLeverageCash);

  const user = useSelector(selectUser);

  const [isClientView, , , , , isAgencyLeadView] = useRole();

  const onEditRecord = (record: any) => () => {
    if (isClientView || isAgencyLeadView) {
      return;
    }
    setModalData({
      ...record,
      amount: percentageFormatter(record.amount, false),
      maxUsableAmount: percentageFormatter(record.maxUsableAmount, false),
    });
    setModalVisibility(true);
  };

  const titlesToBold = ['Total Cash Used', 'Remaining Shortfall', 'New COH'];

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Items',
      dataIndex: 'cashSource',
      key: 'cashSource',
      ellipsis: true,
      width: 250,

      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          undefined,
          titlesToBold,
          'cashSource',
        );
      },
    },
    {
      title: 'Amount Available',
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      width: 220,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'cashSource');
      },
      //   width: 80,
    },
    {
      title: 'Use',
      dataIndex: 'use',
      key: 'use',
      ellipsis: true,
      width: 220,
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          '$',
          titlesToBold,
          'cashSource',
          record.cashSource === 'Remaining Shortfall',
        );
      },
      //   width: 80,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      // width: 250,
      ellipsis: true,
      render: (text: string, record: any) => {
        return (
          <StatusComponent
            amount={
              record.cashSource !== 'Remaining Shortfall' &&
              record.cashSource !== 'Total Cash Used'
                ? record.amount
                : undefined
            }
            use={record.use}
          />
        );
      },
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'type',
      key: 'type',
      width: 80,
      fixed: 'right',
      clientViewHidden: true,
      render: (text: any, record: any) => {
        if (record.isEditable) {
          return (
            <EditOutlined
              onClick={onEditRecord(record)}
              className="c-edit-record-btn"
            />
          );
        }
      },
    },
  ].filter((c: any) => {
    if (isClientView || isAgencyLeadView) {
      return !c.clientViewHidden;
    }
    return true;
  });

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleFormSubmission = (values: any) => {
    if (isLoading || isClientView || isAgencyLeadView) {
      return;
    }
    try {
      const data = { ...values };
      delete data.isEditable;
      delete data.editableFields;

      if (user?.token) {
        data.userId = user.user_id;
        data.propertyId = propertyId;

        return dispatch(
          updateLeverageCash({ token: user.token, values: data }),
          // @ts-ignore
        ).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="annual-figures__section">
      <div className="annual-figures__section-title">Using Cash</div>
      {isModalVisible && (
        <LeverageCashModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Data`}
          onSubmit={handleFormSubmission}
          validationSchema={leverageCashValidationSchema}
          isDraggable
        />
      )}
      <Table
        columns={columns}
        dataSource={dataSource}
        size={useTableSize()}
        pagination={false}
        scroll={{ x: true }}
        bordered
      />
    </div>
  );
};

export default LeverageCash;
