import React from 'react';

function PlayButton({
  color = 'white',
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      className={className}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 7.74239C16.75 8.30133 16.75 9.69867 15.75 10.2576L2.25 17.8033C1.25 18.3622 -5.04736e-08 17.6635 0 16.5457L6.81393e-07 1.45433C7.31867e-07 0.336453 1.25 -0.362219 2.25 0.19672L15.75 7.74239Z"
        fill={color}
      />
    </svg>
  );
}

export default PlayButton;
